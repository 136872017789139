import {
	cilAlignCenter,
	cilAlignLeft,
	cilAlignRight,
	cilApplicationsSettings,
	cilArrowRight,
	cilArrowTop,
	cilArrowBottom,
	cilArrowLeft,
	cilArrowCircleLeft,
	cilArrowCircleRight,
	cilArrowCircleBottom,
	cilArrowCircleTop,
	cilWarning,
	cilInfinity,
	cilMenu,
	cilBell,
	cilCalculator,
	cilChartPie,
	cilCursor,
	cilDrop,
	cilNotes,
	cilPencil,
	cilPuzzle,
	cilSpeedometer,
	cilStar,
	cilEnvelopeOpen,
	cilUser,
	cilLockLocked,
	cilLockUnlocked,
	cilReload,
	cilAccountLogout,
	cilBan,
	cilWifiSignal2,
	cilWifiSignal4,
	cilEthernet,
	cibSignal,
	cibFurryNetwork,
	cilScrubber,
	cilMagnifyingGlass,
	cilWindowRestore,
	cilSync,
	cilChevronLeft,
	cilChevronRight,
	cilChevronDoubleLeft,
	cilChevronDoubleRight,
	cilCheck,
	cilCheckAlt,
	cilCheckCircle,
	cilFlagAlt,
	cilContact,
	cilHome,
	cilCloudUpload,
	cilXCircle,
	cilNoteAdd,
	cilTouchApp,
	cilMoney,
	cibAddthis,
	cilCog,
	cilCenterFocus,
	cilFindInPage,
	cilBuilding,
	cilOptions,
	cilChevronBottom,
	cilChevronTop,
	cilFax,
	cilFile,
	cilFeaturedPlaylist,
	cibFacebook,
	cilSearch,
	cilBirthdayCake,
	cilDollar,
	cilWallpaper,
	cilTags,
	cilX,
	cilGridSlash,
	cilScreenSmartphone,
	cilLink,
	cilAddressBook,
	cilUserX,
	cilBookmark,
	cilClone,
	cilDescription,
	cilClock,
	cilWc,
	cilPeople,
	cilLowVision,
	asdas,
} from '@coreui/icons';

export const icons = Object.assign({}, {
	//asdas,//-->BUAT_TEST
	cilLowVision,
	cilPeople,
	cilWc,
	cilClock,
	cilDescription,
	cilClone,
	cilBookmark,
	cilUserX,
	cilAddressBook,
	cilLink,
	cilScreenSmartphone,
	cilGridSlash,
	cilX,
	cilTags,
	cilWallpaper,
	cilDollar,
	cilBirthdayCake,
	cilSearch,
	cibFacebook,
	cilFeaturedPlaylist,
	cilFile,
	cilFax,
	cilChevronBottom,
	cilChevronTop,
	cilOptions,
	cilBuilding,
	cilFindInPage,
	cilCenterFocus,
	cilCog,
	cibAddthis,
	cilMoney,
	cilTouchApp,
	cilNoteAdd,
	cilXCircle,
	cilCloudUpload,
	cilHome,
	cilContact,
	cilFlagAlt,
	cilCheckAlt,
	cilCheckCircle,
	cilCheck,
	cilChevronLeft,
	cilChevronRight,
	cilChevronDoubleLeft,
	cilChevronDoubleRight,
	cilSync,
	cilWindowRestore,
	cilMagnifyingGlass,
	cilScrubber,
	cibFurryNetwork,
	cibSignal,
	cilEthernet,
	cilWifiSignal2,
	cilWifiSignal4,
	cilBan,
	cilAccountLogout,
	cilReload,
	cilAlignCenter,
	cilAlignLeft,
	cilAlignRight,
	cilApplicationsSettings,
	cilArrowRight,
	cilArrowTop,
	cilArrowBottom,
	cilArrowLeft,
	cilArrowCircleLeft,
	cilArrowCircleTop,
	cilArrowCircleRight,
	cilArrowCircleBottom,
	cilWarning,
	cilInfinity,
	cilMenu,
	cilBell,
	cilCalculator,
	cilChartPie,
	cilCursor,
	cilDrop,
	cilNotes,
	cilPencil,
	cilPuzzle,
	cilSpeedometer,
	cilStar,
	cilEnvelopeOpen,
	cilUser,
	cilLockLocked,
	cilLockUnlocked,
});