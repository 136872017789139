import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CForm,
	CFormInput,
	CFormTextarea,
	CInputGroup,
	CInputGroupText,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CBadge,
	CLink,
	CTooltip,
	CButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import {
	MyButton,
	MyDialoglookup,
	MyDialogview,
} from '../components/index'
import { UFunc } from '../helpers/functions'
import { Konfirm } from '../helpers/onConfirm'
import { isMobile } from "react-device-detect";
import useSatusehat from '../helpers/useSatusehat'

const pjson 		= require('../../package.json')
const Konfigdata 	= (props) => {//-->UNTUK TSYSTEM_CONFIG dan TSISTEM_SETTING--/
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const { getToken, loadToken } 		= useSatusehat(props);
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");

	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uSystemsettingObj			= useSelector(state => state.gSystemsetting) || {};
	const uEditpengelola			= (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN")

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction);
	const uHandelView 			= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 		= uHeaderActionObj.isHeaderTambah || false;

	const uDatacabangArr		= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const uCabangIDinit			= parseInt(localStorage.getItem("lastcabangid")||"0")
	const uCabangIDArr			= uTokenObj.user_cabangarr ||[];
	const uCabangallowArr		= uTokenObj.userhak!=="FREEROLE" ? uDatacabangArr.filter(vItems=>
		uCabangIDArr.includes((vItems.id||"").toString()) )
		: uDatacabangArr; 
	const uCabangselectedObj 	= useSelector(state => state.gCabangatribut) || {};
	const [uCabanginitObj,setCabanginitObj] = React.useState(uCabangselectedObj);

	const uTokensatusehatObj					= JSON.parse(localStorage.getItem("tokensatusehat") || "{}");
	const [uDataklinikObj,setDataklinikObj]		= React.useState({});
	const [uDatasettingObj,setDatasettingObj]	= React.useState(uSystemsettingObj);
	const [uDOMchange,setDOMchange] 			= React.useState(false);
	const [uDatapropinsiArr,setDatapropinsiArr] = React.useState([]);
	const [uDatakotaArr,setDatakotaArr] 		= React.useState([]);
	const [uDatadistrikArr,setDatadistrikArr]	= React.useState([]);
	const [uDatavillageArr,setDatavillageArr]	= React.useState([]);

	//const uSettingwilayahObj	= JSON.parse(uCabanginitObj.satusehat_wilayahstr || "{}");
	const uSettingwilayahObj	= JSON.parse((uCabanginitObj||{}).satusehat_wilayahstr || "{}");

	//---FORM_VARS--/
	//---END FORM_VARS	--/

	//---DLGLOOKUP_VARS--/
	const [uISDlglookupshow,setDlglookupshow]	= React.useState(false);
	const [uDlglookupObj,setDlglookupObj]		= React.useState({});
	//---END DLGLOOKUP_VARS--/

	//---DLGVIEW_VARS--/
	const [uISDlgviewshow,setDlgviewshow]		= React.useState(false);
	const [uDlgviewdataObj,setDlgviewdataObj]	= React.useState({});
	//---END DLGVIEW_VARS--/

	const hdlKlikSimpanidentitas = () => {
		let vElFocus = document.getElementById("inpkliniknama");
		if((uDataklinikObj.nama_klinik||"").trim()==="") {
			vElFocus && vElFocus.focus();
			showToast("Nama Klinik Harus Diisi !");return;
		}//-*/

		apiSimpanidentitas();
	}
	const hdlKlikGettoken = async() => {
		let vElFocus 	= document.getElementById("inporganizationid");
		if((uDataklinikObj.satusehat_orgid||"")==="") {
			vElFocus && vElFocus.focus();
			showToast("OrganizationID Harus Diisi !"); return;
		}
		vElFocus 	= document.getElementById("inpclientkey");
		if((uDataklinikObj.satusehat_clientkey||"")==="") {
			vElFocus && vElFocus.focus();
			showToast("Client Key Harus Diisi !"); return;
		}
		vElFocus 	= document.getElementById("inpsecretkey");
		if((uDataklinikObj.satusehat_secretkey||"")==="") {
			vElFocus && vElFocus.focus();
			showToast("Secret Key Harus Diisi !"); return;
		}

		if((uTokensatusehatObj.access_token||"") !== "") {
			if(await Konfirm("<b>Token Akan DIRESET</b>.."+
				"<br/>Apakah Anda Yakin ?"+
				"") ) initLoadtoken();
				
			return;
		}

		initLoadtoken();
	}
	const hdlKlikviewtokensatusehat = () => {
		uDlgviewdataObj.headers = "View Token Satusehat";
		setDlgviewshow(true);
	}
	const hdlKlikProvinsi = () => {
		if(uDatapropinsiArr.length > 0) {
			const vDatalookupArr = uDatapropinsiArr.map(vItems=>{
				return {id:vItems.code,caption:(vItems.name||"").toUpperCase()}
			})
			uDlglookupObj.dlg_jenis		= "PROVINSI";
			uDlglookupObj.items_arr		= vDatalookupArr;
			uDlglookupObj.dlg_headers	= (uBahasaObj.word_lookup||"Lookup")+": "+
				(uBahasaObj.word_propinsi||"Propinsi").toUpperCase();
			setDlglookupshow(true)
			
			return;
		}
		
		const vAccesstoken 		= uTokensatusehatObj.access_token;
 		if(!vAccesstoken) {
 			showToast("<b>Status Token Satusehat BELUM TERKONEKSI</b>.."+
 				"<br/>Mohon Set API KEY dengan Benar, Kemudian Klik GET NEW TOKEN !"+
 				"");return
 		}

		apiLoadpropinsi()
	}
	const hdlKlikKota = () => {
		if(!uSettingwilayahObj.propinsi) {
			showToast("Provinsi Harus Dipilih Terlebih Dahulu !");
			return;
		}

		if(uDatakotaArr.length > 0) {
			const vDatalookupArr = uDatakotaArr.map(vItems=>{
				return {id:vItems.code,caption:(vItems.name||"").toUpperCase()}
			})
			uDlglookupObj.dlg_jenis		= "KOTA";
			uDlglookupObj.items_arr		= vDatalookupArr;
			uDlglookupObj.dlg_headers	= (uBahasaObj.word_lookup||"Lookup")+": "+
				(uBahasaObj.word_kota||"Kota").toUpperCase();
			setDlglookupshow(true)
			
			return;
		}
		
		const vAccesstoken 		= uTokensatusehatObj.access_token;
 		if(!vAccesstoken) {
 			showToast("<b>Status Token Satusehat BELUM TERKONEKSI</b>.."+
 				"<br/>Mohon Set API KEY dengan Benar, Kemudian Klik GET NEW TOKEN !"+
 				"");return
 		}

		apiLoadkota()
	}
	const hdlKlikDistrik = () => {
		if(!uSettingwilayahObj.kota) {
			showToast("Kota Harus Dipilih Terlebih Dahulu !");
			return;
		}

		if(uDatadistrikArr.length > 0) {
			const vDatalookupArr = uDatadistrikArr.map(vItems=>{
				return {id:vItems.code,caption:(vItems.name||"").toUpperCase()}
			})
			uDlglookupObj.dlg_jenis		= "DISTRIK";
			uDlglookupObj.items_arr		= vDatalookupArr;
			uDlglookupObj.dlg_headers	= (uBahasaObj.word_lookup||"Lookup")+": "+
				(uBahasaObj.word_kecamatan||"Kecamatan").toUpperCase();
			setDlglookupshow(true)
			
			return;
		}
		
		const vAccesstoken 		= uTokensatusehatObj.access_token;
 		if(!vAccesstoken) {
 			showToast("<b>Status Token Satusehat BELUM TERKONEKSI</b>.."+
 				"<br/>Mohon Set API KEY dengan Benar, Kemudian Klik GET NEW TOKEN !"+
 				"");return
 		}

		apiLoaddistrik()
	}
	const hdlKlikVillage = () => {
		if(!uSettingwilayahObj.distrik) {
			showToast("Kecamatan Harus Dipilih Terlebih Dahulu !");
			return;
		}

		if(uDatavillageArr.length > 0) {
			const vDatalookupArr = uDatavillageArr.map(vItems=>{
				return {id:vItems.code,caption:(vItems.name||"").toUpperCase()}
			})
			uDlglookupObj.dlg_jenis		= "VILLAGE";
			uDlglookupObj.items_arr		= vDatalookupArr;
			uDlglookupObj.dlg_headers	= (uBahasaObj.word_lookup||"Lookup")+": "+
				(uBahasaObj.caption_desakelurahan||"Kelurahan / Desa").toUpperCase();
			setDlglookupshow(true)
			
			return;
		}
		
		const vAccesstoken 		= uTokensatusehatObj.access_token;
 		if(!vAccesstoken) {
 			showToast("<b>Status Token Satusehat BELUM TERKONEKSI</b>.."+
 				"<br/>Mohon Set API KEY dengan Benar, Kemudian Klik GET NEW TOKEN !"+
 				"");return
 		}

		apiLoadvillage()
	}
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		const vJenislookup = uDlglookupObj.dlg_jenis || "";

		let vIdx;
		if(vJenislookup === "PROVINSI") {
			vIdx = UFunc.getObjectIndeks(uDatapropinsiArr,_IDITEM,"code");
			apiSimpanwilayah(vJenislookup,vIdx)
		} else if(vJenislookup === "KOTA") {
			vIdx = UFunc.getObjectIndeks(uDatakotaArr,_IDITEM,"code");
			apiSimpanwilayah(vJenislookup,vIdx)
		} else if(vJenislookup === "DISTRIK") {
			vIdx = UFunc.getObjectIndeks(uDatadistrikArr,_IDITEM,"code");
			apiSimpanwilayah(vJenislookup,vIdx)
		} else if(vJenislookup === "VILLAGE") {
			vIdx = UFunc.getObjectIndeks(uDatavillageArr,_IDITEM,"code");
			apiSimpanwilayah(vJenislookup,vIdx)
		}
	}
	const hdlKlikSetorganisasi = () => {
		if((uTokensatusehatObj.access_token||"") === "") {
			showToast(
				"<b>Token Satusehat Belum Terkoneksi</b>.."+
				"<br/>Mohon Lakukan Koneksi ke Satusehat Ato Perbaiki Key-nya !"+
				""); return;
		}

		apiRegisterorganisasi();
	}
	const hdlKlikSetlokasi = () => {
		if((uTokensatusehatObj.access_token||"") === "") {
			showToast(
				"<b>Token Satusehat Belum Terkoneksi</b>.."+
				"<br/>Mohon Lakukan Koneksi ke Satusehat Ato Perbaiki Key-nya !"+
				""); return;
		}

		apiRegisterlokasi();
	}

	const initLoadcabang = () => {
		//--LOAD_CABANG_LOGIN--/
		setDataklinikObj(uCabanginitObj);
		//--END LOAD_CABANG_LOGIN--/

		/*//--TIDAK_DIPAKAI--/
		uDataklinikObj.id_cabang 	= uCabanginitObj.id_cabang;
		uDataklinikObj.kode_cabang 	= uCabanginitObj.kode_cabang;
		uDataklinikObj.nama_klinik 	= uCabanginitObj.nama_klinik;
		uDataklinikObj.telp_hp 		= uCabanginitObj.telp_hp;
		uDataklinikObj.alamat_klinik 		= uCabanginitObj.alamat_klinik;

		uDataklinikObj.jml_ruangan 	= uCabanginitObj.jml_ruangan;
		uDataklinikObj.footer_nota 	= uCabanginitObj.footer_nota;

		setEfekview(!uEfekview);
		//--END TIDAK_DIPAKAI--*/
	}
	const initLoadtoken = () => {
		const vEldisabled	= document.getElementById("btnGettoken");
		const vNextfunction = () => {
			console.log("(Konfigdata - initLoadtoken) => "+JSON.stringify(uTokensatusehatObj));
			vEldisabled && (vEldisabled.disabled = false);
		}

		vEldisabled && (vEldisabled.disabled = true);
		getToken(vNextfunction);
	}

	const contentKlinikidentitas = () => {
		return (
		<CCard className="classbgcard h-100">
		<CCardHeader><b>Identitas Klinik</b></CCardHeader>
		
		<CCardBody>
		<div className="d-none">{JSON.stringify(uCabanginitObj)}</div>
		<CRow className="my-2">
		<CCol xs="4">Nama Klinik</CCol>
		<CCol className="">
			<CFormInput size="sm" maxLength={55}
				value={uDataklinikObj.nama_klinik||""}
				onChange={(e)=>{uDataklinikObj.nama_klinik=e.target.value;setEfekview(!uEfekview);}}
				id="inpkliniknama"/>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="4">Kode Klinik</CCol>
		<CCol className="">
			<CFormInput size="sm"
				value={uDataklinikObj.kode_cabang||"UDF"}
				onChange={(e)=>{uDataklinikObj.kode_cabang=e.target.value;setEfekview(!uEfekview);}}
				readOnly={true}
				id="inpklinikkode"/>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom d-none d-md-block"/>

		<CRow className="my-2">
		<CCol xs="4">
			<small className="d-md-none">Telp/HP</small>
			<span className="d-none d-md-block">Telp/HP Klinik</span>
		</CCol>
		<CCol className="">
			<CFormInput size="sm" maxLength={45}
				value={uDataklinikObj.telp_hp||""}
				onChange={(e)=>{uDataklinikObj.telp_hp=e.target.value;setEfekview(!uEfekview);}}
				id="inpkliniktelp"/>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom d-md-none"/>

		<CRow className="my-2">
		<CCol md="4">
			<small className="d-md-none">Alamat Klinik</small>
			<span className="d-none d-md-block">Alamat Klinik</span>
		</CCol>
		<CCol className="">
			<CFormTextarea size="sm" rows={2}
				value={UFunc.br2nl(uDataklinikObj.alamat_klinik||"")}
				onChange={(e)=>{uDataklinikObj.alamat_klinik=UFunc.nl2br(e.target.value);setEfekview(!uEfekview);}}
				id="inpklinikalamat"/>
			<div className="classpetunjuk">*) {uBahasaObj.petunjuk_textarea}</div>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol xs="4">
			<small className="d-md-none">Longitude</small>
			<span className="d-none d-md-block">Longitude Lokasi</span>
		</CCol>
		<CCol className="">
			<CFormInput size="sm" type="text"
				value={uDataklinikObj.longitude||""}
				onChange={(e)=>{uDataklinikObj.longitude=e.target.value;setEfekview(!uEfekview);}}
				disabled={!uEditpengelola}
				id="inplongitude"/>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="4">
			<small className="d-md-none">Latitude</small>
			<span className="d-none d-md-block">Latitude Lokasi</span>
		</CCol>
		<CCol className="">
			<CFormInput size="sm" type="text"
				value={uDataklinikObj.latitude||""}
				onChange={(e)=>{uDataklinikObj.latitude=e.target.value;setEfekview(!uEfekview);}}
				disabled={!uEditpengelola}
				id="inplatitude"/>
			<div className="classpetunjuk">
			{"*)"} Dapatkan Melalui <a href='https://support.google.com/maps/answer/18539?hl=en&co=GENIE.Platform%3DDesktop' target='_blank'>Google MAP</a>
			</div>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol xs="4">
			<small className="d-md-none">Jml Ruang</small>
			<span className="d-none d-md-block">Jml Ruang</span>
		</CCol>
		<CCol md="4">
			<CFormInput size="sm" type="number"
				value={uDataklinikObj.jml_ruangan||"1"}
				onChange={(e)=>{uDataklinikObj.jml_ruangan=e.target.value;setEfekview(!uEfekview);}}
				onFocus={(e)=>e.target.select()}
				disabled={!uEditpengelola}
				className="text-end"
				id="inpklinikruang"/>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol md="4">
			<small className="d-md-none">Footer Nota</small>
			<span className="d-none d-md-block">Footer Nota</span>
		</CCol>
		<CCol className="">
			<CFormInput size="sm" maxLength={255}
				value={uDataklinikObj.footer_nota||""}
				onChange={(e)=>{uDataklinikObj.footer_nota=UFunc.capitalize(e.target.value);setEfekview(!uEfekview);}}
				disabled={!uEditpengelola}
				id="inpklinikfooternota"/>
		</CCol>
		</CRow>
		</CCardBody>
		
		<CCardFooter className="text-center">
			<MyButton
				disabled={!uEditpengelola}
				onClick={()=>hdlKlikSimpanidentitas()}
				iconname="cilCheckAlt"
				id="btnSimpanidentitas">Simpan</MyButton>
		</CCardFooter>
		</CCard>
		)//->
	}
	const contentSatusehat = () => {
		const vCabangID 	= parseInt(uKeywordObj.kwd_konfigdata) || uCabangIDinit;

		return (
		<CCard className="classbgcard h-100">
		<CCardHeader><b>Setting Satusehat</b></CCardHeader>

		<CCardBody>
		<CRow className="my-2">
		<CCol xs="4">
			<small className="d-md-none">OrganizationID</small>
			<span className="d-none d-md-block">OrganizationID</span>
		</CCol>
		<CCol className="">
			<CFormInput size="sm" type="text" maxLength={100}
				value={uDataklinikObj.satusehat_orgid||""}
				onChange={(e)=>{uDataklinikObj.satusehat_orgid=e.target.value;setEfekview(!uEfekview);setDOMchange(true);}}
				disabled={!uEditpengelola}
				id="inporganizationid"/>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="4">
			<small className="d-md-none">Client ID</small>
			<span className="d-none d-md-block">Client ID</span>
		</CCol>
		<CCol className="">
			<CFormInput size="sm" type="text" maxLength={100}
				value={uDataklinikObj.satusehat_clientkey||""}
				onChange={(e)=>{uDataklinikObj.satusehat_clientkey=e.target.value;setEfekview(!uEfekview);setDOMchange(true);}}
				disabled={!uEditpengelola}
				id="inpclientkey"/>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="4">
			<small className="d-md-none">Client Secret</small>
			<span className="d-none d-md-block">Client Secret</span>
		</CCol>
		<CCol className="">
			<CFormInput size="sm" type="text" maxLength={100}
				value={uDataklinikObj.satusehat_secretkey||""}
				onChange={(e)=>{uDataklinikObj.satusehat_secretkey=e.target.value;setEfekview(!uEfekview);setDOMchange(true);}}
				disabled={!uEditpengelola}
				id="inpsecretkey"/>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol xs="4">
			<small className="d-md-none">Status Token</small>
			<span className="d-none d-md-block">Status Token</span>
		</CCol>
		<CCol className="">
			{vCabangID === uCabangIDinit ? (
			<>
				{(uTokensatusehatObj.access_token||"") === "" ? (
				<CBadge color="danger" className="px-3">
					<CIcon icon="cilX" className="align-middle me-1" height={23}/>
					Satusehat Diconnected
				</CBadge>
				) : (
				<CBadge color="success" className="px-3">
					<CIcon icon="cilCheckAlt" className="align-middle me-1" height={23}/>
					Satusehat Connected
				</CBadge>
				)}
			</>
			) : (
			<CBadge color="warning" className="px-3">
				<CIcon icon="cilGridSlash" className="align-middle me-1" height={23}/>
				Satusehat Unavailable
			</CBadge>
			)}
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="4"/>
		<CCol className="">
			{vCabangID === uCabangIDinit && (
			<>
			<MyButton onClick={()=>hdlKlikGettoken()} id="btnGettoken">
				<small>Get New Token<CIcon icon="cilArrowRight" className="ms-2 align-middle" height={20}/></small>
			</MyButton>

			<span className="px-2">&middot;</span>

			<CTooltip content="--Lihat Token Satusehat">
			<CLink className="classcursorpointer" onClick={()=>hdlKlikviewtokensatusehat()}>
			<CIcon icon="cilTags" className="classikonbox" height={30}/>
			</CLink>
			</CTooltip>
			</>
			)}

			<div className="classpetunjuk py-2">*) Status Token Hanya Dapat Terkoneksi, Jika Anda Login dengan Cabang yang Sama..</div>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol xs="4">
			<small className="d-md-none">Organisasi Satusehat</small>
			<span className="d-none d-md-block">Organisasi Satusehat</span>
		</CCol>
		<CCol className="">
			{vCabangID === uCabangIDinit ? (
				<div className="mb-2 d-grid gap-2">
					<CButton size="sm" onClick={()=>hdlKlikProvinsi()} color="dark" variant="outline" className="py-1" id="btnsetpropinsi">
					{uSettingwilayahObj.propinsi ? (
						<b className="text-uppercase">{(uSettingwilayahObj.propinsi||{}).name || ""}</b>
					) : (
						<small>Pilih Provinsi</small>
					)}
					<CIcon icon="cilSearch" className="ms-2 align-middle"/>
					</CButton>

					<CButton size="sm" onClick={()=>hdlKlikKota()} color="primary" variant="outline" className="py-1" id="btnsetkota">
					{uSettingwilayahObj.kota ? (
						<b className="text-uppercase">{(uSettingwilayahObj.kota||{}).name || ""}</b>
					) : (
						<small>Pilih Kota</small>
					)}
					<CIcon icon="cilSearch" className="ms-2 align-middle"/>
					</CButton>

					<CButton size="sm" onClick={()=>hdlKlikDistrik()} color="warning" variant="outline" className="py-1" id="btnsetdisktrik">
					{uSettingwilayahObj.distrik ? (
						<b className="text-uppercase">{(uSettingwilayahObj.distrik||{}).name || ""}</b>
					) : (
						<small>Pilih Kecamatan</small>
					)}
					<CIcon icon="cilSearch" className="ms-2 align-middle"/>
					</CButton>

					<CButton size="sm" onClick={()=>hdlKlikVillage()} color="success" variant="outline" className="py-1" id="btnsetdesa">
					{uSettingwilayahObj.village ? (
						<b className="text-uppercase">{(uSettingwilayahObj.village||{}).name || ""}</b>
					) : (
						<small>Pilih Kelurahan/Desa</small>
					)}
					<CIcon icon="cilSearch" className="ms-2 align-middle"/>
					</CButton>
				</div>
			) : (
			<>
				{uSettingwilayahObj.village && (
				<>
					{uSettingwilayahObj.propinsi && (
						<b className="text-uppercase">{(uSettingwilayahObj.propinsi||{}).name || ""}</b>
					)}

					{uSettingwilayahObj.kota && (
					<>
						<span className="mx-1">&middot;</span>
						<b className="text-uppercase text-primary">{(uSettingwilayahObj.kota||{}).name || ""}</b>
					</>
					)}

					{uSettingwilayahObj.distrik && (
					<>
						<span className="mx-1">&middot;</span>
						<b className="text-uppercase text-warning">{(uSettingwilayahObj.distrik||{}).name || ""}</b>
					</>
					)}

					{uSettingwilayahObj.village && (
					<>
						<span className="mx-1">&middot;</span>
						<b className="text-uppercase text-success">{(uSettingwilayahObj.village||{}).name || ""}</b>
					</>
					)}

					<div className="my-2 classborderbottom"/>
				</>
				)}
			</>
			)}

			{(uDataklinikObj.satusehat_organizationuid||"")==="" ? (
			<>
				{vCabangID === uCabangIDinit ? (
				<>
				<div className="my-2 classborderbottom"/>
				<MyButton onClick={()=>hdlKlikSetorganisasi()} id="btnSetorganisasi">
					<small>Proses Register<CIcon icon="cilArrowRight" className="ms-2 align-middle" height={20}/></small>
				</MyButton>
				</>
				) : (
				<small className="text-muted fst-italic">&middot; Organisasi Klinik Unset &middot;</small>
				)}
			</>
			) : (
			<>
				<MyButton className="d-none" onClick={()=>hdlKlikSetorganisasi()} id="btnSetorganisasi">
					<small>Proses Register<CIcon icon="cilArrowRight" className="ms-2 align-middle" height={20}/></small>
				</MyButton>
				<div>{uDataklinikObj.satusehat_organizationvalue || "UNDF Organisasi"}</div>
				<small className="fst-italic fw-bolder">{uDataklinikObj.satusehat_organizationuid}</small>
			</>
			)}
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol xs="4">
			<small className="d-md-none">Lokasi Satusehat</small>
			<span className="d-none d-md-block">Lokasi Satusehat</span>
		</CCol>
		<CCol className="">
			{(uDataklinikObj.satusehat_locationuid||"")==="" ? (
			<>
				{vCabangID === uCabangIDinit ? (
				<>
				<MyButton onClick={()=>hdlKlikSetlokasi()} id="btnSetlokasi">
					<small>Proses Register<CIcon icon="cilArrowRight" className="ms-2 align-middle" height={20}/></small>
				</MyButton>
				</>
				) : (
				<small className="text-muted fst-italic">&middot; Lokasi Klinik Unset &middot;</small>
				)}
			</>
			) : (
			<>
				<MyButton className="d-none" onClick={()=>hdlKlikSetlokasi()} id="btnSetlokasi">
					<small>Proses Register<CIcon icon="cilArrowRight" className="ms-2 align-middle" height={20}/></small>
				</MyButton>
			<div>{uDataklinikObj.satusehat_locationdisplay || "UNDF Location"}</div>
			<small className="fst-italic fw-bolder">{uDataklinikObj.satusehat_locationuid}</small>
			</>
			)}
		</CCol>
		</CRow>
		</CCardBody>
		</CCard>
		)//>
	}
	const contentSettingdiskon = () => {
		return (
		<CCard className="classbgcard">
		<CCardHeader><b>Diskon Terapi</b>
			<small className="classfontsmaller fst-italic"> &middot; Untuk Semua Outlet Klinik</small>
		</CCardHeader>

		<CCardBody>
		<CRow className="my-2">
		<CCol xs="6" lg="4" className="classfontsmaller">Diskon Maksimal</CCol>
		<CCol xs="6" lg="3" className="">
			<CInputGroup size="sm">
			<CFormInput size="sm" type="number"
				value={uDatasettingObj.persen_diskonmax||""}
				onChange={(e)=>{uDatasettingObj.persen_diskonmax=e.target.value;setEfekview(!uEfekview);setDOMchange(true);}}
				onFocus={(e)=>e.target.select()}
				disabled={!uEditpengelola}
				className="text-end"
				id="inpsettingdiskon"/>
			<CInputGroupText>%</CInputGroupText>
			</CInputGroup>
		</CCol>
		</CRow>
		</CCardBody>
		</CCard>
		)//>
	}
	const contentSettingshadow = () => {
		if(uTokenObj.userhak !== "FREEROLE") return "";

		return (
		<CCard className="classbgcard mt-3">
		<CCardHeader><b>Setting Server</b>
			<small className="classfontsmaller fst-italic"> &middot; Untuk Semua Outlet Klinik</small>
		</CCardHeader>

		<CCardBody>
		<CRow className="my-2">
		<CCol xs="6" lg="4" className="classfontsmaller">Persen Shadow</CCol>
		<CCol xs="6" lg="3" className="">
			<CInputGroup size="sm">
			<CFormInput size="sm" type="number"
				value={uDatasettingObj.persen_shadow||"0"}
				onChange={(e)=>{uDatasettingObj.persen_shadow=e.target.value;setEfekview(!uEfekview);setDOMchange(true);}}
				onFocus={(e)=>e.target.select()}
				disabled={!uEditpengelola}
				className="text-end"
				id="inpsettingshadow"/>
			<CInputGroupText>%</CInputGroupText>
			</CInputGroup>
		</CCol>
		</CRow>
		</CCardBody>
		</CCard>
		)//>
	}
	const contentSettingdata = () => {
		return (
		<CCard className="classbgcard h-100">
		<CCardHeader><b>Setting Lainnya</b>
			<small className="classfontsmaller fst-italic"> &middot; Untuk Semua Outlet Klinik</small>
		</CCardHeader>

		<CCardBody>
		<CRow className="my-2">
		<CCol xs="6" lg="3" className="classfontsmaller">Jml Tahun Laporan</CCol>
		<CCol xs="6" lg="3" className="">
			<CFormInput size="sm" type="number"
				value={uDatasettingObj.jml_tahun_laporan||""}
				onChange={(e)=>{uDatasettingObj.jml_tahun_laporan=e.target.value;setEfekview(!uEfekview);setDOMchange(true);}}
				onFocus={(e)=>e.target.select()}
				disabled={!uEditpengelola}
				className="text-end"
				id="inpsettingtahunjml"/>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="6" lg="3" className="classfontsmaller">Tahun Lahir Awal</CCol>
		<CCol xs="6" lg="3" className="">
			<CFormInput size="sm" type="number"
				value={uDatasettingObj.start_tahunlahir||""}
				onChange={(e)=>{uDatasettingObj.start_tahunlahir=e.target.value;setEfekview(!uEfekview);setDOMchange(true);}}
				onFocus={(e)=>e.target.select()}
				disabled={!uEditpengelola}
				className="text-end"
				id="inpsettingtahunlahir"/>
		</CCol>
		</CRow>
		</CCardBody>
		</CCard>
		)//>
	}
	const contentDlgview = () => {
		if(!uISDlgviewshow) return (<></>)//>

 		//style='margin:auto 5%;padding:10px 18px;border:1px dashed black;border-radius:10px;background-color:white;'>		return (
 		return (
		<div className="border border-dashed rounded-3 p-3 px-5 bg-light">
		<pre>{JSON.stringify(uTokensatusehatObj,undefined,5)}</pre>
		</div>
		)//>
	}

	const apiLoadcabang = (_IDCABANG) => {
		setCabanginitObj();
		const vEldisabled = document.getElementById("inpcabangid")
		vEldisabled && (vEldisabled.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_cabangid: _IDCABANG,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_konfigdata/ld_cabangattr";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true)
			vEldisabled && (vEldisabled.disabled = false)
			setLoading(false)
			if(output_string.tampil) {
				const vTmpObj = JSON.parse(output_string.cabangatribut || "{}");
				setCabanginitObj(vTmpObj);
			} else {
				setCabanginitObj(uCabangselectedObj);
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Konfigdata - apiLoadcabang) output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired();
				}
			}
		})
		.catch((error) =>{
			setCabanginitObj(uCabangselectedObj);
			vEldisabled && (vEldisabled.disabled = false)
			setLoading(false);
			console.log("(Konfigdata - apiLoadcabang) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiSimpanidentitas = () => {
		const vEldisabled = document.getElementById("btnSimpanidentitas")
		vEldisabled && (vEldisabled.disabled = true)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vCabangID 		= parseInt(uKeywordObj.kwd_konfigdata) || uCabangIDinit;
		const vDataidentitasObj	= uDataklinikObj;

		const vDATAS	= JSON.stringify({
			send_cabangid: vCabangID,
			send_dataklinik: JSON.stringify(vDataidentitasObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_konfigdata/pr_identitas";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true)
			vEldisabled && (vEldisabled.disabled = false)
			setLoading(false)
			if(output_string.sukses) {
				const vTmpObj 			= uCabanginitObj;
				vTmpObj.nama_klinik 	= uDataklinikObj.nama_klinik;
				vTmpObj.telp_hp 		= uDataklinikObj.telp_hp;
				vTmpObj.alamat_klinik 	= uDataklinikObj.alamat_klinik;

				vTmpObj.latitude 		= uDataklinikObj.latitude;
				vTmpObj.longitude 		= uDataklinikObj.longitude;

				vTmpObj.jml_ruangan 	= uDataklinikObj.jml_ruangan;
				vTmpObj.footer_nota 	= uDataklinikObj.footer_nota;

				if(vCabangID === uCabangIDinit) {
					uDispatch({type: "set", gCabangatribut:vTmpObj});
				}

				setCabanginitObj(vTmpObj);
				showToast(uBahasaObj.pesan_sukses||"Berhasil Disimpan !","SUKSES");
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Konfigdata - apiSimpanidentitas) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vEldisabled && (vEldisabled.disabled = false)
			setLoading(false);
			console.log("(Konfigdata - apiSimpanidentitas) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiSimpanfield = (_FIELD) => {
		_FIELD	= (_FIELD||"").trim().toLowerCase();

		let vEldisabled;
		if(_FIELD==="satusehat_orgid")
			vEldisabled = document.getElementById("inporganizationid")
		else if(_FIELD==="satusehat_clientkey")
			vEldisabled = document.getElementById("inpclientkey")
		else if(_FIELD==="satusehat_secretkey")
			vEldisabled = document.getElementById("inpsecretkey")
		
		vEldisabled && (vEldisabled.readOnly = true)

		/*//--TESTING_FRONTEND--/
		console.log("(Konfigdata - apiSimpanfield) uDataklinikObj => "+uDataklinikObj[_FIELD])
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vCabangID 		= parseInt(uDataklinikObj.id_cabang||"0");
		const vDataidentitasObj	= uDataklinikObj;
		const vDataklinikObj	= {
			field:_FIELD,
			value:uDataklinikObj[_FIELD]
		};
		const vDATAS	= JSON.stringify({
			send_cabangid: vCabangID,
			send_dataklinik: JSON.stringify(vDataklinikObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_konfigdata/pr_klinik";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true)
			vEldisabled && (vEldisabled.readOnly = false)
			if(output_string.sukses) {
				const vTmpObj 		= uCabanginitObj;
				vTmpObj[_FIELD] 	= uDataklinikObj[_FIELD];

				if(vCabangID === uCabangIDinit) {
					uDispatch({type: "set", gCabangatribut:vTmpObj});
				}

				//console.log("(Konfigdata - apiSimpanfield) output_string.cabangatribut : "+output_string.cabangatribut);
				setCabanginitObj(vTmpObj);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Konfigdata - apiSimpanfield) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vEldisabled && (vEldisabled.readOnly = false)
			console.log("(Konfigdata - apiSimpanfield) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiSimpansetting = (_FIELD) => {
		_FIELD	= (_FIELD||"").trim().toLowerCase();

		let vEldisabled;
		if(_FIELD==="persen_diskonmax")
			vEldisabled = document.getElementById("inpsettingdiskon")
		else if(_FIELD==="jml_tahun_laporan")
			vEldisabled = document.getElementById("inpsettingtahunjml")
		else if(_FIELD==="start_tahunlahir")
			vEldisabled = document.getElementById("inpsettingtahunlahir")
		else if(_FIELD==="persen_shadow")
			vEldisabled = document.getElementById("inpsettingshadow")
		
		vEldisabled && (vEldisabled.readOnly = true)

		/*//--TESTING_FRONTEND--/
		console.log("(Konfigdata - apiSimpansetting) uDataklinikObj => "+uDatasettingObj[_FIELD])
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDataidentitasObj	= {
			field: _FIELD,
			value: uDatasettingObj[_FIELD]
		};
		const vDATAS	= JSON.stringify({
			send_datasetting: JSON.stringify(vDataidentitasObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_konfigdata/pr_setting";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true)
			vEldisabled && (vEldisabled.readOnly = false)
			if(output_string.sukses) {
				const vSystemsettingObj	= uSystemsettingObj;
				vSystemsettingObj[_FIELD] = uDatasettingObj[_FIELD];

				uDispatch({type: "set", gSystemsetting: vSystemsettingObj});
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Konfigdata - apiSimpansetting) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vEldisabled && (vEldisabled.readOnly = false)
			console.log("(Konfigdata - apiSimpansetting) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoadpropinsi = () => {
		//const vTokensatusehatObj= JSON.parse(localStorage.getItem("tokensatusehat")||"{}");
		const vAccesstoken 		= uTokensatusehatObj.access_token;
		
 		if(!vAccesstoken) {
 			loadToken(apiLoadpropinsi); return;
 		}
		const vLimitedsec		= parseInt(uTokensatusehatObj.expires_in || "0");
		const vTimestampnow 	= new Date();
		const vTimetoken		= new Date(parseInt(uTokensatusehatObj.issued_at || "0"));
		const vSelisih			= UFunc.Datetimeselisih(vTimetoken,vTimestampnow).dtk;

		if(vSelisih > vLimitedsec) {
 			loadToken(apiLoadpropinsi); return;
		}

		const vElbutton 	= document.getElementById("btnsetpropinsi");
		vElbutton && (vElbutton.disabled = true);
		setLoading(true);

		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return;//--*/

		const vDATAS	= JSON.stringify({
			send_accesstoken : vAccesstoken,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_init/ld_propinsi";

		fetch(vURLs,{
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			if(output_string.tampil) {
				//console.log("(Konfigdata - apiLoadpropinsi) output_string.datapropinsi => "+output_string.datapropinsi)
				const vTmpArr = JSON.parse(output_string.datapropinsi || "[]");
				setDatapropinsiArr(vTmpArr);

				const vDatalookupArr = vTmpArr.map(vItems=>{
					return {id:vItems.code,caption:(vItems.name||"").toUpperCase()}
				})
				uDlglookupObj.dlg_jenis		= "PROVINSI";
				uDlglookupObj.items_arr		= vDatalookupArr;
				uDlglookupObj.dlg_headers	= (uBahasaObj.word_lookup||"Lookup")+": "+
					(uBahasaObj.word_propinsi||"Provinsi").toUpperCase();
				
				setDlglookupshow(true)
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Konfigdata - apiLoadpropinsi) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}//-*/
		})
		.catch((error) =>{
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			console.log("(Konfigdata - apiLoadpropinsi) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoadkota = () => {
		const vAccesstoken 		= uTokensatusehatObj.access_token;
		
 		if(!vAccesstoken) {
 			loadToken(apiLoadkota); return;
 		}
		const vLimitedsec		= parseInt(uTokensatusehatObj.expires_in || "0");
		const vTimestampnow 	= new Date();
		const vTimetoken		= new Date(parseInt(uTokensatusehatObj.issued_at || "0"));
		const vSelisih			= UFunc.Datetimeselisih(vTimetoken,vTimestampnow).dtk;

		if(vSelisih > vLimitedsec) {
 			loadToken(apiLoadkota); return;
		}

		const vElbutton 	= document.getElementById("btnsetkota");
		vElbutton && (vElbutton.disabled = true);
		setLoading(true);

		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return;//--*/

		const vDATAS	= JSON.stringify({
			send_accesstoken : vAccesstoken,
			send_kodepropinsi : uSettingwilayahObj.propinsi.code || "0",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_init/ld_kota";

		fetch(vURLs,{
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			if(output_string.tampil) {
				//console.log("(Konfigdata - apiLoadpropinsi) output_string.datapropinsi => "+output_string.datapropinsi)
				const vTmpArr = JSON.parse(output_string.datakota || "[]");
				setDatakotaArr(vTmpArr);

				const vDatalookupArr = vTmpArr.map(vItems=>{
					return {id:vItems.code,caption:(vItems.name||"").toUpperCase()}
				})
				uDlglookupObj.dlg_jenis		= "KOTA";
				uDlglookupObj.items_arr		= vDatalookupArr;
				uDlglookupObj.dlg_headers	= (uBahasaObj.word_lookup||"Lookup")+": "+
					(uBahasaObj.word_kota||"kota").toUpperCase();
				
				setDlglookupshow(true)
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Konfigdata - apiLoadkota) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}//-*/
		})
		.catch((error) =>{
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			console.log("(Konfigdata - apiLoadkota) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoaddistrik = () => {
		const vAccesstoken 		= uTokensatusehatObj.access_token;
		
 		if(!vAccesstoken) {
 			loadToken(apiLoaddistrik); return;
 		}
		const vLimitedsec		= parseInt(uTokensatusehatObj.expires_in || "0");
		const vTimestampnow 	= new Date();
		const vTimetoken		= new Date(parseInt(uTokensatusehatObj.issued_at || "0"));
		const vSelisih			= UFunc.Datetimeselisih(vTimetoken,vTimestampnow).dtk;

		if(vSelisih > vLimitedsec) {
 			loadToken(apiLoaddistrik); return;
		}

		const vElbutton 	= document.getElementById("btnsetdisktrik");
		vElbutton && (vElbutton.disabled = true);
		setLoading(true);

		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return;//--*/

		const vDATAS	= JSON.stringify({
			send_accesstoken : vAccesstoken,
			send_kodekota : uSettingwilayahObj.kota.code || "0",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_init/ld_distrik";

		fetch(vURLs,{
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			if(output_string.tampil) {
				//console.log("(Konfigdata - apiLoadpropinsi) output_string.datadistrik => "+output_string.datadistrik)
				const vTmpArr = JSON.parse(output_string.datadistrik || "[]");
				setDatadistrikArr(vTmpArr);

				const vDatalookupArr = vTmpArr.map(vItems=>{
					return {id:vItems.code,caption:(vItems.name||"").toUpperCase()}
				})
				uDlglookupObj.dlg_jenis		= "DISTRIK";
				uDlglookupObj.items_arr		= vDatalookupArr;
				uDlglookupObj.dlg_headers	= (uBahasaObj.word_lookup||"Lookup")+": "+
					(uBahasaObj.word_kecamatan||"kecamatan").toUpperCase();
				
				setDlglookupshow(true)
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Konfigdata - apiLoaddistrik) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}//-*/
		})
		.catch((error) =>{
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			console.log("(Konfigdata - apiLoaddistrik) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoadvillage = () => {
		const vAccesstoken 		= uTokensatusehatObj.access_token;
		
 		if(!vAccesstoken) {
 			loadToken(apiLoadvillage); return;
 		}
		const vLimitedsec		= parseInt(uTokensatusehatObj.expires_in || "0");
		const vTimestampnow 	= new Date();
		const vTimetoken		= new Date(parseInt(uTokensatusehatObj.issued_at || "0"));
		const vSelisih			= UFunc.Datetimeselisih(vTimetoken,vTimestampnow).dtk;

		if(vSelisih > vLimitedsec) {
 			loadToken(apiLoadvillage); return;
		}

		const vElbutton 	= document.getElementById("btnsetdesa");
		vElbutton && (vElbutton.disabled = true);
		setLoading(true);

		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return;//--*/

		const vDATAS	= JSON.stringify({
			send_accesstoken : vAccesstoken,
			send_kodedistrik : uSettingwilayahObj.distrik.code || "0",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_init/ld_village";

		fetch(vURLs,{
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			if(output_string.tampil) {
				//console.log("(Konfigdata - apiLoadpropinsi) output_string.datapropinsi => "+output_string.datapropinsi)
				const vTmpArr = JSON.parse(output_string.datavillage || "[]");
				setDatavillageArr(vTmpArr);

				const vDatalookupArr = vTmpArr.map(vItems=>{
					return {id:vItems.code,caption:(vItems.name||"").toUpperCase()}
				})
				uDlglookupObj.dlg_jenis		= "VILLAGE";
				uDlglookupObj.items_arr		= vDatalookupArr;
				uDlglookupObj.dlg_headers	= (uBahasaObj.word_lookup||"Lookup")+": "+
					(uBahasaObj.caption_desakelurahan||"Kelurahan / Desa").toUpperCase();
				
				setDlglookupshow(true)
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Konfigdata - apiLoadvillage) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}//-*/
		})
		.catch((error) =>{
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			console.log("(Konfigdata - apiLoadvillage) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiSimpanwilayah = (_JENIS,_IDXDATA) => {
		_JENIS 	= (_JENIS || "").toUpperCase();
		_IDXDATA= parseInt(_IDXDATA);

		let vEldisabled;
		let vParameterObj
		if(_JENIS === "PROVINSI") {
			vEldisabled = document.getElementById("btnsetpropinsi")
			vParameterObj = uDatapropinsiArr[_IDXDATA] || undefined;
			if(_IDXDATA < 0 && !uSettingwilayahObj.propinsi) {
				setDlglookupshow(false); return
			}
		} else if(_JENIS === "KOTA") {
			vEldisabled = document.getElementById("btnsetkota")
			vParameterObj = uDatakotaArr[_IDXDATA] || undefined;
			if(_IDXDATA < 0 && !uSettingwilayahObj.kota) {
				setDlglookupshow(false); return
			}
		} else if(_JENIS === "DISTRIK") {
			vEldisabled = document.getElementById("btnsetdisktrik")
			vParameterObj = uDatadistrikArr[_IDXDATA] || undefined;
			if(_IDXDATA < 0 && !uSettingwilayahObj.distrik) {
				setDlglookupshow(false); return
			}
		} else if(_JENIS === "VILLAGE") {
			vEldisabled = document.getElementById("btnsetdesa")
			vParameterObj = uDatavillageArr[_IDXDATA] || undefined;
			if(_IDXDATA < 0 && !uSettingwilayahObj.village) {
				setDlglookupshow(false); return
			}
		} else { setDlglookupshow(false); return }

		vEldisabled && (vEldisabled.disabled = true)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vCabangID 		= parseInt(uKeywordObj.kwd_konfigdata) || uCabangIDinit;
		const vDataidentitasObj	= uDataklinikObj;

		const vDATAS	= JSON.stringify({
			send_cabangid: vCabangID,
			send_jenis: _JENIS,
			send_datapars: JSON.stringify(vParameterObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_konfigdata/pr_wilayah";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true)
			vEldisabled && (vEldisabled.disabled = false)
			setLoading(false)
			if(output_string.sukses) {
				const vTmpObj 					= uCabanginitObj;

				if(_JENIS === "PROVINSI") {
					uSettingwilayahObj.propinsi = vParameterObj;
					uSettingwilayahObj.kota 	= undefined;
					uSettingwilayahObj.distrik 	= undefined;
					uSettingwilayahObj.village 	= undefined;

					setDatakotaArr([]);setDatadistrikArr([]);setDatavillageArr([]);
				} else if(_JENIS === "KOTA") {
					uSettingwilayahObj.kota 	= vParameterObj;
					uSettingwilayahObj.distrik 	= undefined;
					uSettingwilayahObj.village 	= undefined;
					setDatadistrikArr([]);setDatavillageArr([]);
				} else if(_JENIS === "DISTRIK") {
					uSettingwilayahObj.distrik 	= vParameterObj;
					uSettingwilayahObj.village 	= undefined;
					setDatavillageArr([]);
				} else if(_JENIS === "VILLAGE") {
					uSettingwilayahObj.village 	= vParameterObj;
				}
				vTmpObj.satusehat_organizationuid 		= "";
				vTmpObj.satusehat_organizationvalue 	= "";
				vTmpObj.satusehat_wilayahstr 	= JSON.stringify(uSettingwilayahObj);

				if(vCabangID === uCabangIDinit) {
					uDispatch({type: "set", gCabangatribut:vTmpObj});
				}

				setCabanginitObj(vTmpObj);

				setDlglookupshow(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Konfigdata - apiSimpanwilayah) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vEldisabled && (vEldisabled.disabled = false)
			setLoading(false);
			console.log("(Konfigdata - apiSimpanwilayah) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiRegisterorganisasi = () => {
		//const vTokensatusehatObj= JSON.parse(localStorage.getItem("tokensatusehat")||"{}");
		const vAccesstoken 		= uTokensatusehatObj.access_token;
		
 		if(!vAccesstoken) {
 			loadToken(apiRegisterorganisasi); return;
 		}
		const vLimitedsec		= parseInt(uTokensatusehatObj.expires_in || "0");
		const vTimestampnow 	= new Date();
		const vTimetoken		= new Date(parseInt(uTokensatusehatObj.issued_at || "0"));
		const vSelisih			= UFunc.Datetimeselisih(vTimetoken,vTimestampnow).dtk;

		if(vSelisih > vLimitedsec) {
 			loadToken(apiRegisterorganisasi); return;
		}

		const vCabangID 	= parseInt(uDataklinikObj.id_cabang||"0");
		const vElbutton 	= document.getElementById("btnSetorganisasi");
		vElbutton && (vElbutton.disabled = true);
		setLoading(true);

		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);

			uDataklinikObj.satusehat_organizationvalue 	= "Ini Nama Organisasi";
			uDataklinikObj.satusehat_organizationuid 	= "34rwefs234wfs";

			const vTmpObj 		= uCabanginitObj;
			vTmpObj.satusehat_organizationvalue = uDataklinikObj.satusehat_organizationvalue;
			vTmpObj.satusehat_organizationuid 	= uDataklinikObj.satusehat_organizationuid;

			if(vCabangID === uCabangIDinit) {
				uDispatch({type: "set", gCabangatribut:vTmpObj});
			}
		},2000); return;//--*/

		const vDATAS	= JSON.stringify({
			send_accesstoken : vAccesstoken,
			send_datajson : JSON.stringify(uDataklinikObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_konfigdata/pr_registerorganisasi";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then(async(output_string) => {
			setSessionaktif(true);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				uDataklinikObj.satusehat_organizationvalue 	= output_string.org_value || "";
				uDataklinikObj.satusehat_organizationuid 	= output_string.org_uid || "";

				if(vCabangID === uCabangIDinit) {
					const vTmpObj 		= uCabanginitObj || {};
					vTmpObj.satusehat_organizationvalue = uDataklinikObj.satusehat_organizationvalue;
					vTmpObj.satusehat_organizationuid 	= uDataklinikObj.satusehat_organizationuid;
					
					uDispatch({type: "set", gCabangatribut:vTmpObj});
				}
				
				//setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Konfigdata - apiRegisterorganisasi) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			console.log("(Konfigdata - apiRegisterorganisasi) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiRegisterlokasi = () => {
		//const vTokensatusehatObj= JSON.parse(localStorage.getItem("tokensatusehat")||"{}");
		const vAccesstoken 		= uTokensatusehatObj.access_token;
		
 		if(!vAccesstoken) {
 			loadToken(apiRegisterlokasi); return;
 		}
		const vLimitedsec		= parseInt(uTokensatusehatObj.expires_in || "0");
		const vTimestampnow 	= new Date();
		const vTimetoken		= new Date(parseInt(uTokensatusehatObj.issued_at || "0"));
		const vSelisih			= UFunc.Datetimeselisih(vTimetoken,vTimestampnow).dtk;

		if(vSelisih > vLimitedsec) {
 			loadToken(apiRegisterlokasi); return;
		}

		const vCabangID 	= parseInt(uDataklinikObj.id_cabang||"0");
		const vElbutton 	= document.getElementById("btnSetlokasi");
		vElbutton && (vElbutton.disabled = true);
		setLoading(true);

		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
		},2000); return;//--*/

		const vDATAS	= JSON.stringify({
			send_accesstoken : vAccesstoken,
			send_datajson : JSON.stringify(uDataklinikObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_konfigdata/pr_registerlokasi";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then(async(output_string) => {
			setSessionaktif(true);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				const vDisplay 	= output_string.location_display || "";
				const vUid 	 	= output_string.location_uid || "";

				uDataklinikObj.satusehat_locationdisplay 	= vDisplay;
				uDataklinikObj.satusehat_locationuid 		= vUid;

				if(vCabangID === uCabangIDinit) {
					const vTmpObj 		= uCabanginitObj || {};
					vTmpObj.satusehat_locationdisplay 	= vDisplay;
					vTmpObj.satusehat_locationuid 		= vUid;

					uDispatch({type: "set", gCabangatribut:vTmpObj});
				}//-*/
				
				//setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Konfigdata - apiRegisterlokasi) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			console.log("(Konfigdata - apiRegisterlokasi) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		setCabanginitObj(uCabangselectedObj);
		/*uDatasettingObj.persen_diskonmax	= uSystemsettingObj.persen_diskonmax;
		uDatasettingObj.jml_tahun_laporan	= uSystemsettingObj.jml_tahun_laporan;
		uDatasettingObj.start_tahunlahir	= uSystemsettingObj.start_tahunlahir;//-*/

		return ()=>{
			uDispatch({type: "set", gInitHeaderAction: {}});

			uKeywordObj.kwd_konfigdata = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));

	    	setCabanginitObj();//uCabangselectedObj
	    	setDlgviewshow(false);
	    }
	},[])
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		const vCabangID 	= parseInt(uKeywordObj.kwd_konfigdata) || uCabangIDinit;

		if(vCabangID === uCabangIDinit) {
			setCabanginitObj(uCabangselectedObj)
			return
		}

		apiLoadcabang(vCabangID);
	},[uHandelView])
	React.useEffect(()=>{
		if(UFunc.isEmpty(uCabanginitObj)) return;

	    initLoadcabang();
	},[uCabanginitObj])
	React.useEffect(()=>{
		if(!uISDlgviewshow) 
			{ setDlgviewdataObj({}); return }
	},[uISDlgviewshow])
	React.useEffect(()=>{
		if(!uISDlglookupshow) 
			{ setDlglookupObj({}); return }
	},[uISDlglookupshow])
	React.useEffect(()=>{
		if((uDataklinikObj.satusehat_orgid === undefined
			|| uDataklinikObj.satusehat_orgid === null)
			) return;
		if(!uDOMchange) return;

		let vTimeout = setTimeout(()=>{ apiSimpanfield("satusehat_orgid"); setDOMchange(false) },1000);
		return () => clearTimeout(vTimeout); 
	},[uDataklinikObj.satusehat_orgid]);
	React.useEffect(()=>{
		if((uDataklinikObj.satusehat_clientkey === undefined 
			|| uDataklinikObj.satusehat_clientkey === null)
			) return;
		if(!uDOMchange) return;

		let vTimeout = setTimeout(()=>{ apiSimpanfield("satusehat_clientkey"); setDOMchange(false) },1000);
		return () => clearTimeout(vTimeout); 
	},[uDataklinikObj.satusehat_clientkey]);
	React.useEffect(()=>{
		if((uDataklinikObj.satusehat_secretkey === undefined
			|| uDataklinikObj.satusehat_secretkey === null)
			) return;
		if(!uDOMchange) return;

		let vTimeout = setTimeout(()=>{ apiSimpanfield("satusehat_secretkey"); setDOMchange(false) },1000);
		return () => clearTimeout(vTimeout); 
	},[uDataklinikObj.satusehat_secretkey]);
	React.useEffect(()=>{
		if(uDatasettingObj.persen_diskonmax === undefined 
			|| uDatasettingObj.persen_diskonmax === null
			) return;
		if(!uDOMchange) return;
	
		let vTimeout = setTimeout(()=>{ apiSimpansetting("persen_diskonmax");setDOMchange(false); },1000);
		return () => clearTimeout(vTimeout); 
	},[uDatasettingObj.persen_diskonmax])
	React.useEffect(()=>{
		if(uDatasettingObj.persen_shadow === undefined 
			|| uDatasettingObj.persen_shadow === null
			) return;
		if(!uDOMchange) return;
	
		let vTimeout = setTimeout(()=>{ apiSimpansetting("persen_shadow");setDOMchange(false); },1000);
		return () => clearTimeout(vTimeout); 
	},[uDatasettingObj.persen_shadow])
	React.useEffect(()=>{
		if(uDatasettingObj.jml_tahun_laporan === undefined 
			|| uDatasettingObj.jml_tahun_laporan === null
			) return;
		if(!uDOMchange) return;
			
		let vTimeout = setTimeout(()=>{ apiSimpansetting("jml_tahun_laporan");setDOMchange(false); },1000);
		return () => clearTimeout(vTimeout); 
	},[uDatasettingObj.jml_tahun_laporan])
	React.useEffect(()=>{
		if(uDatasettingObj.start_tahunlahir === undefined 
			|| uDatasettingObj.start_tahunlahir === null
			) return;
		if(!uDOMchange) return;
			
		let vTimeout = setTimeout(()=>{ apiSimpansetting("start_tahunlahir");setDOMchange(false); },1000);
		return () => clearTimeout(vTimeout); 
	},[uDatasettingObj.start_tahunlahir])

	//console.log("Konfigdata - uActiveroute => "+(uActiveroute));
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	
	return (
		<>
		<div className="d-none">{JSON.stringify(uCabanginitObj)}</div>
		<CForm>
		<CRow className="align-items-stretch">
		<CCol md="6" className="mb-3">{contentKlinikidentitas()}</CCol>
		<CCol md="6" className="mb-3">{contentSatusehat()}</CCol>
		</CRow>

		<div className="mb-4 mt-1 classborderbottom"/>

		<CRow className="align-items-stretch">
		<CCol md="6" className="mb-3">{contentSettingdiskon()}{contentSettingshadow()}</CCol>
		<CCol md="6" className="mb-3">{contentSettingdata()}</CCol>
		</CRow>
		</CForm>

		<MyDialoglookup
			show={uISDlglookupshow}
			toggle={()=>setDlglookupshow(false)}
			dialogHeader={uDlglookupObj.dlg_headers||"Lookup Data"}
			inputValue={uDlglookupObj.dlg_input||""}
			dataItemArr={uDlglookupObj.items_arr||[]} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"
			{...props}/>

		<MyDialogview
			options={{centered:true}}
			show={uISDlgviewshow} 
			dialogHeader={uDlgviewdataObj.headers||""}
			toggle={()=>setDlgviewshow(false)}
			renderContent={contentDlgview()}/>
		</>
	)//>
}	

export default Konfigdata