import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CFormCheck,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CPagination,
	CPaginationItem,
	CLink,
	CTooltip,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,CDropdownDivider,
	CFormTextarea,
	CBadge,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
//import NumberFormat from 'react-number-format';
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogform,
	MyDialogview,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 		= require('../../package.json')
const Lapkunjungan	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettinguserObj 			= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uConfigappsObj			= useSelector(state => state.uConfigappsObj) || {}; 
	const uSystemsettingObj			= useSelector(state => state.gSystemsetting);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettinguserObj.jml_mobile || 25) : 100;
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uISDemoapp				= uConfigappsObj.level_app === "DEMO";
	const uSelisihrangetanggal 		= useSelector(state => state.gSelisihrangetanggal);
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelReset 		= uHeaderActionObj.isHeaderreset || false;

	//--VIEWS_VARS--/
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const uIsHapusallow					= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uIsDataloaded,setDataloaded]	= React.useState(false); 
	const [uCaptionObj,setCaptionObj]	= React.useState({}); 
	const [uFilterObj,setFilterObj]		= React.useState({});
	const [uFirstload,setFirstload]		= React.useState("YA");
	const [uSortedObj,setSortedObj]		= React.useState({});

	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uDataselectID,setDataselectID]= React.useState(0);

	const uDatacabangArr				= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const [uDatadefaultArr,setDatadefaultArr]= React.useState([]);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
		const [uDatasumArr,setDatasumObj]	= React.useState({});
	//--END VIEWS_VARS--/

	//--INIT_SELECTED_BEFORE--/
	const uLastinitObj 					= useSelector(state => state.gInitTabelSelected);// || {};
	const [uInitselectedObj,setInitselectedObj] = React.useState({});
	//--END INIT_SELECTED_BEFORE--*/

	//--DLGFORM--/
	const [uISDlgformshow,setDlgformshow]		= React.useState(false);
	const [uFormdataObj,setFormdataObj]			= React.useState({});
	//--END DLGFORM--/

	//--DLGVIEW--/
	const [uISDlgviewshow,setDlgviewshow]		= React.useState(false);
	const [uDlgviewdataObj,setDlgviewdataObj]	= React.useState({});
	//--END DLGVIEW--/

	//--DOM_VARS--/
	const uElJmlData					= document.getElementById("idjmldata");
	const [uElBtnsimpan,setElBtnsimpan]	= React.useState(document.getElementById("btnDialogSimpan"));
	const fileType 		= "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	//--END DOM_VARS--/

	//--SETTING_CETAK--/
	const uHTMLstylelaporan		= useSelector(state => state.gHTMLstyleLaporan);
	const uFrameExport 			= document.getElementById("ifmcontentstoprint");
	//--END SETTING_CETAK--/

	//--HANDEL--/
	const hdlKlikDlgsimpan = () => {
		//--VALIDASI_FILTER--/
		let vElfocus	= document.getElementById("inpcabangid");
		if(parseInt(uFormdataObj.cabang_id||"0") <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Cabang Harus Dipilih !");
			return
		}

		vElfocus		= document.getElementById("inptglawal");
		const vTglawal 	= uFormdataObj.tgl_awal;
		if(!UFunc.isDateValid(vTglawal)) {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Awal Laporan Tidak Valid !"); return
		}
		const vTglawalDT	= UFunc.toDate(vTglawal);
		if(UFunc.DateIsBeyond(vTglawalDT)) {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Awal Laporan Melewati Tanggal SAAT INI ! "); return
		}

		vElfocus		= document.getElementById("inptglakhir");
		const vTglakhir	= uFormdataObj.tgl_akhir;
		if(!UFunc.isDateValid(vTglawal)) {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Akhir Laporan Tidak Valid !"); return
		}
		const vTglakhirDT	= UFunc.toDate(vTglakhir);
		const vTglnowDT		= new Date();
		const vTglbatasDT	= new Date(vTglnowDT.getFullYear(),vTglnowDT.getMonth()+1,0);

		//alert(vTglbatasDT+"<br/>"+vTglakhirDT);return;
		if(UFunc.SelisihHari(UFunc.DbDate(vTglbatasDT),UFunc.DbDate(vTglakhirDT)) > 0) {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Akhir Laporan Melewati Tanggal BULAN INI ! "); return
		}

		if(UFunc.SelisihHari(UFunc.DbDate(vTglawalDT),UFunc.DbDate(vTglawalDT)) > uSelisihrangetanggal) {
			vElfocus && vElfocus.focus();
			showToast("Maksimal Selisih Range Tanggal adalah "+uSelisihrangetanggal+" hari.."+
				"<br/>Mohon Diperbaiki !"+
				""); return
		}
		//alert(vTanggalDT);return;
		//--END VALIDASI_FILTER--/

		setDataloaded(false);
		apiLoadall();
	}
	const hdlKlikSort = (_NAMAKOL) => {
		_NAMAKOL		= (_NAMAKOL||"").toUpperCase();
		const vNamenow	= (uSortedObj.nama||"").toUpperCase();
		const vSortnow	= (uSortedObj.jenis||"").toUpperCase();

		const vSortedObj		= uSortedObj;
		vSortedObj.init_aktif 	= "YA";
		if(vNamenow==="" || vNamenow !== _NAMAKOL) {
			vSortedObj.nama 	= _NAMAKOL.toLowerCase();
			vSortedObj.jenis 	= "ASCENDING";
		} else if(vNamenow===_NAMAKOL) {
			if(vSortnow==="ASCENDING")
				vSortedObj.jenis = "DESCENDING";
			else if(vSortnow==="DESCENDING") {
				 vSortedObj.nama 	= "";
				 vSortedObj.jenis 	= "";
			}
		}
		vSortedObj.init_aktif 	= undefined;

		setSortedObj(vSortedObj);
		setEfekview(!uEfekview);
	}
	const hdlKlikDetil = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL<=0) return;

		setDataselectID(_IDTABEL);
		const vArrIdx 	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowidx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setRowselect(vRowidx);

		uDlgviewdataObj.dlg_header 	= "Detil Kunjungan: "+(uDatamainArr[vArrIdx].caption_pasien||"").toUpperCase();
		uDlgviewdataObj.data_obj	= uDatamainArr[vArrIdx] || {}; 
		if((uDlgviewdataObj.data_obj.detil_arr || []).length > 0) {
			setDlgviewshow(true);
			return;
		}

		apiLoaddetil(_IDTABEL);
	}
	const hdlKlikCetak = () => {
		if(uDatamainArr.length <= 0) return;

		const vContent 	= contentCetak();
		if(vContent === "") return
		//console.log("(Lapasuransi - hdlKlikCetak) vContent = "+vContent)

		var pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.window.focus();
		pri.window.print();
	}
	const hdlKlikExcel = () => {
		if(uDatamainArr.length <= 0) return;

		contentExcelall();
	}
	//--END HANDEL--/

	//--INIT--/
	const initResettampilan = () => {
		setCaptionObj({});
		setDatamainArr([]);
		setDataselectID(0); setRowselect(-1); setPageActive(1);
		setFilterObj({}); setSortedObj({});
	}
	const initJmldata = () => {
		uElJmlData 
			&& (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	}
	//--END INIT--/

	//--CONTENT_FUNC--/
	const contentLaporan = () => {
		if(!uIsDataloaded) return (<></>)//->

		//console.log("(Lapkunjungan - contentLaporan) uFilterObj.asuransi_id => "+uFilterObj.asuransi_id);
		if(uJmlData <= 0) return (
			<div className="mx-md-5 px-lg-5">
			&middot;&middot; {uBahasaObj.caption_dataempty||"Data Tidak Ada"} &middot;&middot;
			</div>
		)//->

		const vMin 			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);

		const vStylekolfixed = {
			position: "sticky",
			left: 0,
			background: "#f0f0f0",
			zIndex: 1,
			borderRight:"1px solid #DCDCDC",
		}
		const vWidthtabel	= 950;
		const vDataviewArr	= uDatamainArr.slice(vMin,vMax);
		
		return (
		<div style={{overflowX:"auto"}} id="iddivtabel">
		<table style={{minWidth:vWidthtabel,width:"100%"}}
			className="table table-borderless table-striped table-hover" 
			id="idtabeldata">
		<thead>
			<tr className="d-none"><th colSpan={7}>{vMin} - {vMax}</th></tr>
			<tr className="text-center align-top classfontsmaller">
			<th width={40} className="px-1 border" style={vStylekolfixed}>No</th>
			<th width={150} className="px-1 text-start border" style={{...vStylekolfixed,left:40}}>
				{uBahasaObj.caption_tglkunjungan||"Tgl Kunjungan"}
			</th>
			<th className="px-1 text-start border">{uBahasaObj.word_pasien||"Pasien"}</th>
			<th width={200} className="px-1 text-start border">{uBahasaObj.word_dokter||"Dokter"}</th>
			<th width={125} className="px-1 border">{uBahasaObj.caption_durasitunggu || "Durasi Tunggu"}</th>
			<th width={125} className="px-1 border">{uBahasaObj.caption_durasiperiksa || "Durasi Periksa"}</th>
			<th width={125} className="px-1 border">{uBahasaObj.caption_durasibilling || "Durasi Billing"}</th>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				tgl_kunjungan,caption_pasien,id_pasien,dokter_caption,
				waktu_janjian,waktu_hadir,waktu_periksa_start,waktu_periksa_end,
				waktu_periksa_billing,waktu_selesai,is_loading,
				id
			} = vItems;

			vNumber++;

			const vDurasitungguObj	= UFunc.timeDifference(waktu_janjian,waktu_periksa_start);
			const vDurasiperiksaObj	= UFunc.timeDifference(waktu_periksa_start,waktu_periksa_end);
			const vDurasibillingObj	= UFunc.timeDifference(waktu_periksa_billing,waktu_selesai);

			const vLabelwaktutunggu	= (vDurasitungguObj.totalSeconds || 0) < 30
				? "0 Mnt" 
				: ((vDurasitungguObj.totalSeconds || 0) < 60 ? "1 Mnt"
					: ((vDurasitungguObj.hours || 0) > 0 
						? vDurasitungguObj.hours+" Jam " 
						: "") + ((vDurasitungguObj.minutes || 0) > 0 ? vDurasitungguObj.minutes+" Mnt" : "") );
			const vLabelwaktuperiksa = (vDurasiperiksaObj.totalSeconds || 0) < 30
				? "0 Mnt" 
				: ((vDurasiperiksaObj.totalSeconds || 0) < 60 ? "1 Mnt"
					: ((vDurasiperiksaObj.hours || 0) > 0 
						? vDurasiperiksaObj.hours+" Jam " 
						: "") + ((vDurasiperiksaObj.minutes || 0) > 0 ? vDurasiperiksaObj.minutes+" Mnt" : "") );
			const vLabelwaktubilling = (vDurasibillingObj.totalSeconds || 0) < 30
				? "0 Mnt" 
				: ((vDurasibillingObj.totalSeconds || 0) < 60 ? "1 Mnt"
					: ((vDurasibillingObj.hours || 0) > 0 
						? vDurasibillingObj.hours+" Jam " 
						: "") + ((vDurasibillingObj.minutes || 0) > 0 ? vDurasibillingObj.minutes+" Mnt" : "") );
			
			return (
			<tr className={"align-top text-end"+(uRowselect===vKeys?" classrowselect":"")}
				 id={"idtr"+id}
				 key={vKeys}>
				<td className="px-1 text-end" style={vStylekolfixed}>{vNumber}.</td>
				<td className="px-1 text-start" style={{...vStylekolfixed,left:40}}>
					<div className="d-md-flex justify-content-between">
						<span>{UFunc.TglAngka(tgl_kunjungan)}</span>
						
						<span className="mx-1 d-md-none">&middot;</span>
						
						{(is_loading||false)===true ? (
							<CSpinner color="primary" className="mx-2" size="sm"/>
						) : (
						<CTooltip content="--Detil Kunjungan" className="d-none d-lg-block">
							<CLink onClick={()=>hdlKlikDetil(id)} className="classcursorpointer">
							<CIcon icon="cilSearch" className="classikonbox align-middle" height={23}/>
							</CLink>
						</CTooltip>
						)}
					</div>
				</td>
				
				<td className="px-1 text-start text-info">
					{caption_pasien || "Undf Pasien"}
				</td>
				
				<td className="px-1 text-start fw-bolder">
					<small>{dokter_caption || "Drg.Undf Dokter"}</small>
				</td>
				
				<td className="px-1 text-center classfontsmaller text-primary">{vLabelwaktutunggu}</td>
				<td className="px-1 text-center classfontsmaller text-warning">{vLabelwaktuperiksa}</td>
				<td className="px-1 text-center classfontsmaller text-success">{vLabelwaktubilling}</td>
			</tr>
			)
		})}
		</tbody>

		<tfoot className="fw-bolder bg-primary text-white">
			<tr>
			<td colSpan={2} className="text-center text-capitalize bg-dark text-white" style={vStylekolfixed}>{uBahasaObj.word_rata||"Rata-rata"}</td>
			<td colSpan={2}/>
			<td className="px-1 text-center">{(uDatasumArr.durasi_tunggu)}</td>
			<td className="px-1 text-center">{(uDatasumArr.durasi_periksa)}</td>
			<td className="px-1 text-center">{(uDatasumArr.durasi_billing)}</td>
			</tr>
		</tfoot>
		</table>
		</div>
		)//>
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//->

		const vJmlTahunlaporan	= parseInt(uSettinguserObj.jml_tahun_laporan)||5;
		const vCabangID 		= uFormdataObj.cabang_id || 0;
		const vCabangviewArr	= uTokenObj.userhak==="FREEROLE" ? uDatacabangArr
			: uDatacabangArr.filter(vItems=>(uTokenObj.user_cabangarr || []).includes(vItems.id.toString()))
		const vTahunnow			= (new Date()).getFullYear();

		return (
		<CForm>
		{uDatacabangArr.length > 1 && (
		<CRow className="my-2">
		<CCol xs="3" md="4">Cabang</CCol>
		<CCol className="text-end">
		<CFormSelect size="sm"
			value={uFormdataObj.cabang_id||0}
			onChange={(e)=>{uFormdataObj.cabang_id = e.target.value; setEfekview(!uEfekview)}}
			id="inpcabangid">
			{vCabangviewArr.map((vItems,vKeys)=>{
				const {
					id,cabang_nama
				} = vItems

				return (
				<option value={id} key={vKeys}>{(cabang_nama||"UNDF").toUpperCase()}</option>
				)
			})}
			</CFormSelect>
		</CCol>
		</CRow>
		)}

		<CRow className="my-2">
		<CCol xs="3" md="4">Tanggal</CCol>
		<CCol className="text-end">
			<CInputGroup size="sm">
			<CFormInput size="sm" type="date"
				value={uFormdataObj.tgl_awal}
				onChange={(e)=>{uFormdataObj.tgl_awal=e.target.value;setEfekview(!uEfekview)}}
				id="inptglawal"/>
			<CInputGroupText>s.d.</CInputGroupText>
			<CFormInput size="sm" type="date"
				value={uFormdataObj.tgl_akhir}
				onChange={(e)=>{uFormdataObj.tgl_akhir=e.target.value;setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inptglakhir"/>
			</CInputGroup>
		</CCol>
		</CRow>
		</CForm>
		)
	}
	const contentDlgview = () => {
		if(!uISDlgviewshow) return (<></>)//->

		const vDatadetilObj	= uDlgviewdataObj.data_obj || {};
		const vDatadetilArr	= vDatadetilObj.detil_arr || [];
		
		return(
		<>
		<CRow className="align-items-stretch">
		<CCol lg="6" className="mb-3">
			<CCard className="h-100">
			<CCardHeader className="py-0 pt-1 text-muted classfontsmaller">
				<small>Data Kunjungan</small>
			</CCardHeader>

			<CCardBody>
			<small>
			<div className="d-flex justify-content-between my-1">
				<div>Tgl Kunjungan</div>
				<div className="fw-bolder text-end">
					{UFunc.TglAngka(vDatadetilObj.tgl_kunjungan)}
				</div>
			</div>

			<div className="d-flex justify-content-between my-1">
				<div>Nama Pasien</div>
				<div className="fw-bolder text-end text-info">
					{(vDatadetilObj.caption_pasien || "Undf Pasien")}
				</div>
			</div>

			<div className="d-flex justify-content-between my-1">
				<div>Dokter</div>
				<div className="fw-bolder text-end text-primary">
					{(vDatadetilObj.dokter_caption || "Undf Doker")}
				</div>
			</div>
			</small>
			</CCardBody>
			</CCard>
		</CCol>

		<CCol className="mb-3">
			<CCard className="h-100">
			<CCardHeader className="py-0 pt-1 text-muted classfontsmaller">
				<small>Waktu Kunjungan</small>
			</CCardHeader>

			<CCardBody>
			<small>
			<div className="d-flex justify-content-between my-1">
				<div>Jam Janjian</div>
				<div className="fw-bolder text-end">
					{(vDatadetilObj.waktu_janjian||"-").substr(0,5)}
				</div>
			</div>

			<div className="d-flex justify-content-between my-1">
				<div>Jam Periksa / Billing</div>
				<div className="text-end">
					<b className="text-warning">
					{(vDatadetilObj.waktu_periksa_start||"-").substr(0,5)}
					</b>

					<span className="mx-1">&middot;</span>
					
					<b className="text-info">
					{(vDatadetilObj.waktu_periksa_billing||"-").substr(0,5)}
					</b>

					<span className="mx-1">&middot;</span>
					
					<b className="text-danger">
					{(vDatadetilObj.waktu_periksa_end||"-").substr(0,5)}
					</b>
				</div>
			</div>

			<div className="d-flex justify-content-between my-1">
				<div>Jam Selesai</div>
				<div className="fw-bolder text-end text-success">
					{(vDatadetilObj.waktu_selesai||"-").substr(0,5)}
				</div>
			</div>
			</small>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>

		<CCard className="mb-3">
		<CCardHeader className="py-0 pt-1 text-muted classfontsmaller">
			<small>Data Kunjungan</small>
		</CCardHeader>

		<CCardBody className="px-0">
		<small>
			<table className="table table-borderless table-striped" id="idtabelddetil">
			<thead>
			<tr className="align-top text-center">
				<th width={30} className="">No</th>
				<th className="text-start">Item Terapi</th>
				<th width="25%" className="">&middot;</th>
			</tr>
			</thead>

			<tbody className="classborderbottom">
			{vDatadetilArr.map((vItems,vKeys)=>{
				const {
					diagnosa,keterangan,caption_terapi,qty,id
				} = vItems;

				return (
				<tr className="align-top" key={vKeys}>
					<td className="text-end">{vKeys+1}</td>
					<td className="text-start">
						<div>{caption_terapi||"Undf Terapi"}</div>
						<div className="classfontsmaller">
							{((diagnosa||"")!=="" || (keterangan||"")!=="") && (
							<>
							<div className="my-2 classborderbottom"/>
							(

							{(diagnosa||"")!=="" && (
							<i>{diagnosa}</i>
							)}

							{((diagnosa||"")!=="" && (keterangan||"")!=="") && (
							<span className="mx-1">&middot;</span>
							)}

							{(keterangan||"")!=="" && (
							<b>{keterangan}</b>
							)}
							
							)
							</>
							)}
						</div>
					</td>
					<td className="text-center"><b>{UFunc.formatAngka(qty)}</b> UNT</td>
				</tr>
				)
			})}
			</tbody>
			</table>
		</small>
		</CCardBody>
		</CCard>
		</>
		)//->
	}
	const contentCetak = () => {
		if(uDatamainArr.length <= 0) return "";
		//console.log("(Lapasuransi - contentCetak) vHTMLs => "+vHTMLs)

		const vCabangIdx	= UFunc.getObjectIndeks(uDatacabangArr,uFilterObj.cabang_id);
		const vCabangtext	= vCabangIdx>=0 ? uDatacabangArr[vCabangIdx].cabang_nama : "UNDF";

		const vDatasourceArr= uDatamainArr;
		const vColspan		= 7;

		let vHTMLs 	= uHTMLstylelaporan;
		vHTMLs += `
			<table id="idtabelhtml">
			<thead>
			<tr>
			<td colspan="`+vColspan+`">
			<div><b>`+(uBahasaObj["menus_"+uActiveroute.toLowerCase()]||"Laporan").toUpperCase()+`</b></div>
			<div>`+uCaptionObj.title+`</div>`+
			(uDatacabangArr.length > 1 
				? (`<div>Cabang: `+vCabangtext+`</div>`) 
				: ``)+`
			</td>
			</tr>
			<tr class="idtabelheader" valign="top">
				<th width="7%">No</th>
				<th width="11%">`+(uBahasaObj.word_tanggal||"Tanggal")+`</th>
				<th align="left">`+(uBahasaObj.word_keterangan||"Keterangan")+`</th>
				<th width="13%">`+(uBahasaObj.caption_durasitunggu||"Tunggu")+`</th>
				<th width="13%">`+(uBahasaObj.caption_durasiperiksa||"Periksa")+`</th>
				<th width="13%">`+(uBahasaObj.caption_durasibilling||"Billing")+`</th>
			</tr>
			</thead>
			<tbody>`;

		vHTMLs	+= vDatasourceArr.map((vItems,vKeys)=>{
			const {
				tgl_kunjungan,caption_pasien,id_pasien,dokter_caption,
				waktu_janjian,waktu_hadir,waktu_periksa_start,waktu_periksa_end,
				waktu_periksa_billing,waktu_selesai,
				id
			} = vItems;

			const vDurasitungguObj	= UFunc.timeDifference(waktu_janjian,waktu_periksa_start);
			const vDurasiperiksaObj	= UFunc.timeDifference(waktu_periksa_start,waktu_periksa_end);
			const vDurasibillingObj	= UFunc.timeDifference(waktu_periksa_billing,waktu_selesai);

			const vLabelwaktutunggu	= (vDurasitungguObj.totalSeconds || 0) < 30
				? "0 Mnt" 
				: ((vDurasitungguObj.totalSeconds || 0) < 60 ? "1 Mnt"
					: ((vDurasitungguObj.hours || 0) > 0 
						? vDurasitungguObj.hours+" Jam " 
						: "") + ((vDurasitungguObj.minutes || 0) > 0 ? vDurasitungguObj.minutes+" Mnt" : "") );
			const vLabelwaktuperiksa = (vDurasiperiksaObj.totalSeconds || 0) < 30
				? "0 Mnt" 
				: ((vDurasiperiksaObj.totalSeconds || 0) < 60 ? "1 Mnt"
					: ((vDurasiperiksaObj.hours || 0) > 0 
						? vDurasiperiksaObj.hours+" Jam " 
						: "") + ((vDurasiperiksaObj.minutes || 0) > 0 ? vDurasiperiksaObj.minutes+" Mnt" : "") );
			const vLabelwaktubilling = (vDurasibillingObj.totalSeconds || 0) < 30
				? "0 Mnt" 
				: ((vDurasibillingObj.totalSeconds || 0) < 60 ? "1 Mnt"
					: ((vDurasibillingObj.hours || 0) > 0 
						? vDurasibillingObj.hours+" Jam " 
						: "") + ((vDurasibillingObj.minutes || 0) > 0 ? vDurasibillingObj.minutes+" Mnt" : "") );

			return (`
				<tr key={vKeys} valign="top">
					<td align="right" style="">`+(vKeys+1)+`.</td>
					<td align="left">`+UFunc.TglAngka(tgl_kunjungan)+`</td>
					<td align="left">`+
						`<div>`+(caption_pasien||"UNDF")+`</div>`+
						`<div><small>Dokter: <b>`+(dokter_caption||"UNDF")+`</b></small></div>`+
					`</td>
					<td align="center"><small>`+(vLabelwaktutunggu)+`</small></td>
					<td align="center"><small>`+(vLabelwaktuperiksa)+`</small></td>
					<td align="center"><small>`+(vLabelwaktubilling)+`</small></td>
				</tr>
			`);
		}).join(``);

		vHTMLs += `
			</tbody>
			<tfoot>
			<tr>
			<td colspan="3" align="center">Rata-rata</td>
			<td align="center"><small>`+(uDatasumArr.durasi_tunggu)+`</small></td>
			<td align="center"><small>`+(uDatasumArr.durasi_periksa)+`</small></td>
			<td align="center"><small>`+(uDatasumArr.durasi_billing)+`</small></td>
			</tr>
			</tfoot>
			</table>
		`;
		return vHTMLs;	
	}
	const contentExcelall = () => {
		const vCabangIdx	= UFunc.getObjectIndeks(uDatacabangArr,uFilterObj.cabang_id);
		const vCabangtext	= vCabangIdx>=0 ? uDatacabangArr[vCabangIdx].cabang_nama : "UNDF";

		const vColspan		= 7;

		let vDatasetsArr 	= [];
		let vRowcount		= 0;
		let vColposwidth	= 50;

		let vTmpObj	= {
			no : "",
			tgl_kunjungan: "",
			caption_pasien: "",
			dokter_caption: "",
			durasi_tunggu: "",
			durasi_periksa: "",
			durasi_billing: "",
		};
		vDatasetsArr.push(vTmpObj);

		vRowcount++;
		uDatamainArr.map((vItems,vKeys)=>{
			const {
				tgl_kunjungan,caption_pasien,id_pasien,dokter_caption,
				waktu_janjian,waktu_hadir,waktu_periksa_start,waktu_periksa_end,
				waktu_periksa_billing,waktu_selesai,
				id
			} = vItems;

			vRowcount++;
			vColposwidth = (caption_pasien||"").length > vColposwidth 
				? (caption_pasien||"").length : vColposwidth; 

			const vDurasitungguObj	= UFunc.timeDifference(waktu_janjian,waktu_periksa_start);
			const vDurasiperiksaObj	= UFunc.timeDifference(waktu_periksa_start,waktu_periksa_end);
			const vDurasibillingObj	= UFunc.timeDifference(waktu_periksa_billing,waktu_selesai);

			const vLabelwaktutunggu	= (vDurasitungguObj.totalSeconds || 0) < 30
				? "0 Mnt" 
				: ((vDurasitungguObj.totalSeconds || 0) < 60 ? "1 Mnt"
					: ((vDurasitungguObj.hours || 0) > 0 
						? vDurasitungguObj.hours+" Jam " 
						: "") + ((vDurasitungguObj.minutes || 0) > 0 ? vDurasitungguObj.minutes+" Mnt" : "") );
			const vLabelwaktuperiksa = (vDurasiperiksaObj.totalSeconds || 0) < 30
				? "0 Mnt" 
				: ((vDurasiperiksaObj.totalSeconds || 0) < 60 ? "1 Mnt"
					: ((vDurasiperiksaObj.hours || 0) > 0 
						? vDurasiperiksaObj.hours+" Jam " 
						: "") + ((vDurasiperiksaObj.minutes || 0) > 0 ? vDurasiperiksaObj.minutes+" Mnt" : "") );
			const vLabelwaktubilling = (vDurasibillingObj.totalSeconds || 0) < 30
				? "0 Mnt" 
				: ((vDurasibillingObj.totalSeconds || 0) < 60 ? "1 Mnt"
					: ((vDurasibillingObj.hours || 0) > 0 
						? vDurasibillingObj.hours+" Jam " 
						: "") + ((vDurasibillingObj.minutes || 0) > 0 ? vDurasibillingObj.minutes+" Mnt" : "") );


			vTmpObj	= {
				no : {v:vKeys+1,t:"n"},
				tgl_kunjungan : {v: UFunc.toDate(tgl_kunjungan),t:"d",s:{alignment:{horizontal:"left"},}},
				caption_pasien: (caption_pasien||"Undf Pasien"),
				dokter_caption: (dokter_caption||"Undf Dokter"),
				durasi_tunggu : {v: vLabelwaktutunggu,t:"s",s:{alignment:{horizontal:"center"}, }},
				durasi_periksa : {v: vLabelwaktuperiksa,t:"s",s:{alignment:{horizontal:"center"}, }},
				durasi_billing : {v: vLabelwaktubilling,t:"s",s:{alignment:{horizontal:"center"}, }},
			}
			vDatasetsArr.push(vTmpObj);
		});

		//--SUM_NILAI--/
		vTmpObj	= {
			no : {v: "Rata-rata",t:"s",s:{alignment:{horizontal:"center"},font:{bold:true} }},
			tgl_kunjungan: "",
			caption_pasien: "",
			dokter_caption: "",
			durasi_tunggu : {v: uDatasumArr.durasi_tunggu,t:"s",s:{alignment:{horizontal:"center"},font:{bold:true} }},
			durasi_periksa : {v: uDatasumArr.durasi_periksa,t:"s",s:{alignment:{horizontal:"center"},font:{bold:true} }},
			durasi_billing : {v: uDatasumArr.durasi_billing,t:"s",s:{alignment:{horizontal:"center"},font:{bold:true} }},
		};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//--END SUM_NILAI--/

		const wsrows	= [{hpt:43}]
		const wscols	= [
			{wpx:40},
			{wpx:90},
			{wch:vColposwidth},
			{wpx:200},
			{wpx:100},
			{wpx:100},
			{wpx:150},
		];
		
		const wsmerge	= [
			{s:{r:0,c:0},e:{r:0,c:vColspan - 1}},
			{s:{r:vRowcount,c:0},e:{r:vRowcount,c:vColspan - 4}}
		]

		const vTanggalnowDT	= new Date()
		const vNamafile 	= 
			vTanggalnowDT.getFullYear()+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+
			UFunc.leadZero(vTanggalnowDT.getDate())+"_LAPKUNJUNGAN_"+
			(uFilterObj.tgl_awal||"").replace(/-/g,"")+"_"+
			(uFilterObj.tgl_akhir||"").replace(/-/g,"")+
		"";
		const vSheetlabel = 
			(uFilterObj.tgl_awal||"").replace(/-/g,"")+"_"+
			(uFilterObj.tgl_akhir||"").replace(/-/g,"");

		const ws 		= XLSX.utils.json_to_sheet(vDatasetsArr);

		//--CUSTOM_HEADER--/
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			"\r\nPeriode "+UFunc.HariAngka(uFilterObj.tgl_awal)+" s.d. "+
			UFunc.HariAngka(uFilterObj.tgl_akhir)+
			(uDatacabangArr.length > 1 ? "\r\nCabang: "+vCabangtext : "" )+
		"";
		const vHeaderArr = [
			{v:"No",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Tgl Kunjungan",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Pasien",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Dokter",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Durasi Tunggu",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Durasi Periksa",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Durasi Billing",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
		];

		XLSX.utils.sheet_add_aoa(ws, [[{v:vJudullaporan,t:"s",s:{
			alignment:{horizontal:"center",wrapText: true},
			font:{bold:true} 
		}}]], { origin: "A1" });
		XLSX.utils.sheet_add_aoa(ws, [vHeaderArr], { origin: "A2" });
		//--END CUSTOM_HEADER--*/

		ws['!rows'] 	= wsrows;
		ws['!cols'] 	= wscols;
		ws["!merges"] 	= wsmerge;

		const wb 		= { Sheets: { [vSheetlabel]: ws }, SheetNames: [vSheetlabel] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
		const data 		= new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, vNamafile + fileExtension);
	}
	//--END CONTENT_FUNC--/

	//--API--/
	const apiLoadawal = () => {}
	const apiLoadall = () => {
		const vTmpFilterObj 		= uFormdataObj;
		vTmpFilterObj.init_change 	= undefined;
		uElBtnsimpan && (uElBtnsimpan.disabled = true)
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= []
		let vTimeout = setTimeout(()=>{
			uElBtnsimpan && (uElBtnsimpan.disabled = false)
			clearTimeout(vTimeout)
			setLoading(false);
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_filterlist : JSON.stringify(vTmpFilterObj),
			//send_pg	: _PAGE <= 0 ? 1 : _PAGE,
			//send_maxdata	: uMaxData,
			send_firstload	: uFirstload,//vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapkunjungan/ld_laporan";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			uElBtnsimpan && (uElBtnsimpan.disabled = false)
			setLoading(false);
			if(output_string.tampil) {
				setFilterObj(vTmpFilterObj);
				const vLoadArr	= JSON.parse(output_string.dataobject||"[]");
				/*//--LOAD_PER_PAGE--/
				vLoadArr.map(vItems=>vItems.pg = parseInt(_PAGE)||1);
				setDatamainArr([...vDatamainArr,...vLoadArr]);
				setJmlData(parseInt(output_string.totaldata||uJmlData));
				//--END LOAD_PER_PAGE--*/

				//--LOAD_ALL_VIEW_PER_PAGE--/
				setDatamainArr(vLoadArr);
				//setJmlData(vLoadArr.length);
				//--END LOAD_ALL_VIEW_PER_PAGE--*/

				setFirstload("TIDAK");
				
				setDlgformshow(false);
				setDataloaded(true);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapkunjungan - apiLoadall) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uElBtnsimpan && (uElBtnsimpan.disabled = false)
			setLoading(false);
			console.log("(Lapkunjungan - apiLoadall) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoaddetil = (_IDTABEL) => {
		_IDTABEL		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArrIdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vArrIdx].is_loading = true;
		setEfekview(!uEfekview);

		const vTmpFilterObj 		= uFilterObj;
		vTmpFilterObj.id_tabel 		= _IDTABEL;
		vTmpFilterObj.id_rekammedis = uDatamainArr[vArrIdx].id_rekammedis;
		vTmpFilterObj.init_change 	= undefined;

		/*//--TESTING_FRONTEND--/
		console.log("vTmpFilterObj.asuransi_id => "+vTmpFilterObj.asuransi_id );
		let vTimeout = setTimeout(()=>{
			uElBtnsimpan && (uElBtnsimpan.disabled = false)
			clearTimeout(vTimeout)
			setLoading(false);
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_filterlist : JSON.stringify(vTmpFilterObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapkunjungan/ld_detil";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);	
			uDatamainArr[vArrIdx].is_loading = false;
			if(output_string.tampil) {
				const vLoadArr	= JSON.parse(output_string.dataobject||"[]");
				//console.log("(Lapkunjungan - apiLoaddetil) vLoadArr => "+JSON.stringify(vLoadArr) );

				uDatamainArr[vArrIdx].detil_arr = vLoadArr;
				uDlgviewdataObj.data_obj 		= uDatamainArr[vArrIdx] || {};

				setDlgviewshow(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapkunjungan - apiLoaddetil) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArrIdx].is_loading = false;
			console.log("(Lapkunjungan - apiLoaddetil) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
		
		apiLoadawal();

		return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});

			setDlgformshow(false); setDlgviewshow(false);
			setDatamainArr([]);
			setDataloaded(false)
		}
	},[])
	React.useEffect(()=>{ initJmldata() },[uElJmlData])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		initJmldata()
	},[uJmlData])
	React.useEffect(()=>{
		const vDatasourceArr= uDatamainArr;

		if(vDatasourceArr.length <= 0)
			{ setDatadefaultArr([]); return }
		setDatadefaultArr([{init_change:"1"},...vDatasourceArr]) 
	},[uDatamainArr])
	React.useEffect(()=>{
		const vDatasourceArr= uDatamainArr;

		//console.log("(Lapkunjungan - [vDatasourceArr.length]) vDatasourceArr => "+JSON.stringify(vDatasourceArr) );
		setJmlData(vDatasourceArr.length);
		if(vDatasourceArr.length <= 0)
			{ setDatasumObj({}); return }

		const vWaktutungguDTK	= vDatasourceArr.reduce((vStores,vItems) => {
			const vDurasiObj	= UFunc.timeDifference(vItems.waktu_janjian,vItems.waktu_periksa_start);
			return vStores + (parseInt(vDurasiObj.totalSeconds)||0);
		}, 0);
		const vWaktuperiksaDTK	= vDatasourceArr.reduce((vStores,vItems) => {
			const vDurasiObj	= UFunc.timeDifference(vItems.waktu_periksa_start,vItems.waktu_periksa_end);
			return vStores + (parseInt(vDurasiObj.totalSeconds)||0);
		}, 0);
		const vWaktubillingDTK	= vDatasourceArr.reduce((vStores,vItems) => {
			const vDurasiObj	= UFunc.timeDifference(vItems.waktu_periksa_billing,vItems.waktu_selesai);
			return vStores + (parseInt(vDurasiObj.totalSeconds)||0);
		}, 0);

		let vJamtmp; let vMenittmp;
		
		vJamtmp 			= Math.floor((vWaktutungguDTK/vDatasourceArr.length) / 3600);
		vMenittmp 			= Math.floor(((vWaktutungguDTK/vDatasourceArr.length) % 3600) / 60);
		const vDurasitunggu	= (vJamtmp <= 0 && vMenittmp <= 0)
			? "0 Mnt" : (vJamtmp > 0 ? vJamtmp+" Jam " : "") + vMenittmp+" Mnt";

		vJamtmp 			= Math.floor((vWaktuperiksaDTK/vDatasourceArr.length) / 3600);
		vMenittmp 			= Math.floor(((vWaktuperiksaDTK/vDatasourceArr.length) % 3600) / 60);
		const vDurasiperiksa= (vJamtmp <= 0 && vMenittmp <= 0)
			? "0 Mnt" : (vJamtmp > 0 ? vJamtmp+" Jam " : "") + vMenittmp+" Mnt";

		vJamtmp 			= Math.floor((vWaktubillingDTK/vDatasourceArr.length) / 3600);
		vMenittmp 			= Math.floor(((vWaktubillingDTK/vDatasourceArr.length) % 3600) / 60);
		const vDurasibilling= (vJamtmp <= 0 && vMenittmp <= 0)
			? "0 Mnt" : (vJamtmp > 0 ? vJamtmp+" Jam " : "") + vMenittmp+" Mnt";

		setDatasumObj({
			durasi_tunggu: vDurasitunggu,
			durasi_periksa: vDurasiperiksa,
			durasi_billing: vDurasibilling,
		});
		setEfekview(!uEfekview)
	},[uDatamainArr,uDatamainArr.length])
	React.useEffect(()=>{
		//--LOAD_ALL_VIEW_PER_PAGE--/
		//setDataselectID(0);
		setRowselect(-1);
		//--END LOAD_ALL_VIEW_PER_PAGE--/
	},[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHtml500msg) return;

		const vHeader = "Pilih Filter Laporan";
		setFormdataObj({...uFilterObj, dlg_header:vHeader, init_change: 1});
		setDlgformshow(true)
	},[uHandelView])
	React.useEffect(()=>{
		if(!uHandelReset) return;

		uHeaderActionObj.isHeaderreset = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHtml500msg) { apiLoadawal(); }
		else { setDataloaded(false); }
	},[uHandelReset])
	React.useEffect(()=>{
		if(!uIsDataloaded)
			{ initResettampilan(); return; }

		//--SET_TITLE--/
		let vTitelmain	= "Periode ";
		vTitelmain += "<b>"+UFunc.HariAngka(uFormdataObj.tgl_awal)+" s.d. "+UFunc.HariAngka(uFormdataObj.tgl_akhir)+"</b>";

		const vCabangIdx= UFunc.getObjectIndeks(uDatacabangArr,uFormdataObj.cabang_id);
		let vTitelsub	= uDatacabangArr.length > 1 ? (uDatacabangArr[vCabangIdx]||{}).cabang_nama+"" : "";
		//vTitelsub		+= "<b>[SEMUA PASIEN]</b>";

		//vTitelsub = "&middot; "+vTitelsub+" &middot;";
		setCaptionObj({title:vTitelmain,title_sub:vTitelsub});
		//--END SET_TITLE--/
	},[uIsDataloaded])
	React.useEffect(()=>{
		//console.log("(Lapkunjungan - [uISDlgformshow]) uFilterObj => "+JSON.stringify(uFilterObj));
		if(!uISDlgformshow) 
			{ setFormdataObj({}); return }

		if(uFormdataObj.cabang_id === undefined)
			uFormdataObj.cabang_id = localStorage.getItem("lastcabangid") 
				|| (uDatacabangArr[0].id || "0");

		const vTanggalnowDT			= new Date();
		const vTanggalLastmonthDT 	= new Date();//UFunc.toDate("2023-02-03");
		if(uFormdataObj.tgl_awal === undefined) {
			vTanggalLastmonthDT.setMonth(vTanggalLastmonthDT.getMonth() - 1);
			uFormdataObj.tgl_awal = vTanggalLastmonthDT.getFullYear()+"-"+
			UFunc.leadZero(vTanggalLastmonthDT.getMonth()+1)+"-26"+
			"";
		}
		if(uFormdataObj.tgl_akhir === undefined)
			uFormdataObj.tgl_akhir = vTanggalnowDT.getFullYear()+"-"+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+"-25"+
			"";

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			setElBtnsimpan(document.getElementById("btnDialogSimpan"));
			
			let vElfocus = document.getElementById("inpcabangid");
			if(!vElfocus)
				vElfocus = document.getElementById("inptglawal");

			if(!isMobile)
				vElfocus && vElfocus.focus();
		},350)
	},[uISDlgformshow])
	React.useEffect(()=>{
		if(!uISDlgviewshow) 
			{ setDlgviewdataObj({}); return }
	},[uISDlgviewshow])
	React.useEffect(()=>{
		if(uDatamainArr.length <= 0) return;
		if(uPageActive > 1) setPageActive(1);
		else 
			{ setRowselect(-1); }

		if(JSON.stringify(uSortedObj||{}) === "{}") { return; }

		let vSortedArr;
		const vNamasort = (uSortedObj.nama||"").toLowerCase();
		const vISNumeric= UFunc.isNumeric(uDatamainArr[0][vNamasort]);
		if(uSortedObj.jenis === "ASCENDING") {
			vSortedArr	= !vISNumeric
				? uDatamainArr.sort((a, b) => {
					return (a[vNamasort] > b[vNamasort]) ? 1 : ((b[vNamasort] > a[vNamasort]) ? -1 : 0) 
					}) 
				: uDatamainArr.sort((a, b) => a[vNamasort] - b[vNamasort]) ;
		}else if(uSortedObj.jenis === "DESCENDING") {
			vSortedArr	= !vISNumeric
				? uDatamainArr.sort((a, b) => {
					return (b[vNamasort] > a[vNamasort]) ? 1 : ((a[vNamasort] > b[vNamasort]) ? -1 : 0) 
				})
				: uDatamainArr.sort((a, b) => b[vNamasort] - a[vNamasort]);
		} else {
			vSortedArr	= uDatadefaultArr.slice(1);//.splice(0,1);
		}
		setDatamainArr(vSortedArr);
		setEfekview(!uEfekview);
	},[uSortedObj,
	uSortedObj.nama,
	uSortedObj.jenis,
	])

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHtml500msg)  return (
		<CCard className="classbgcard">
		<CCardHeader>
		<strong>{uBahasaObj.caption_page500||"Page 500"}</strong>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)

	return (
		<>
		{(!uIsDataloaded) ? (
		<CCard className="classbgcard">
			<CCardHeader className="fw-bolder">Pilih Filter Laporan</CCardHeader>
			<CCardBody className="mx-lg-5 p-lg-5">
			<div className="mx-md-5 p-md-5 fst-italic classpetunjuk">
			*) Silahkan Pilih Filter, untuk menentukan Parameter Laporan yang akan Ditampilkan. Kemudian Tekan SIMPAN. Untuk Mencetak Silahkan KLIK Ikon Cetak..
			</div>
			</CCardBody>
		</CCard>
		) : (
		<CCard className="classbgcard">
		<CCardHeader className="d-flex justify-content-between px-1 px-md-3">
		<div>
			<div className="d-none d-md-block">{UFunc.renderHTML(uCaptionObj.title)}</div>
			<div className="d-md-none classfontsmaller"><small>{UFunc.renderHTML(uCaptionObj.title)}</small></div>
			<span className="classfontsmaller text-primary">
				<small>
				{UFunc.renderHTML(uCaptionObj.title_sub)}
				</small>
			</span>
		</div>

		<div>
			<span className="d-none d-md-inline-block">
				<CTooltip content="--CETAK..">
				<CLink 
					className="classikon classikoncetak classcursorpointer"
					onClick={()=>hdlKlikCetak()}/>
				</CTooltip>

				<CTooltip content="--Export-2-Excel..">
				<CLink 
					className="classikon classikonexcel classcursorpointer"
					onClick={()=>hdlKlikExcel()}/>
				</CTooltip>
				
				<span className="mx-1">&middot;</span>
			</span>

			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeelapse)} ms</small>
			</span>
			<span className="ms-1 d-md-none">&middot;</span>

			<CDropdown className="d-md-none">
				<CDropdownToggle caret={false} size="sm"
					className="p-0" color="transparent">
					<CIcon icon="cilChevronBottom" className="classikonbox mx-1" height={25}/>
				</CDropdownToggle>
				<CDropdownMenu>
					<CDropdownItem onClick={()=>hdlKlikCetak()}>
						<CIcon className="classikoncetak ms-0 me-2 align-middle" height={25}/>
						Cetak Laporan
					</CDropdownItem>
					<CDropdownItem onClick={()=>hdlKlikExcel()}>
						<CIcon className="classikonexcel ms-0 me-2 align-middle" height={25}/>
						Export-2-Excel
					</CDropdownItem>
				</CDropdownMenu>
			</CDropdown>
		</div>
		</CCardHeader>
		
		<CCardBody className="px-0">{contentLaporan()}</CCardBody>

		{(uJmlData > uMaxData) && (
			<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
			</CCardFooter>
		)}
		</CCard>
		)}

		<MyDialogform
			options={{centered:true}}
			onSimpan={()=>hdlKlikDlgsimpan()}
			show={uISDlgformshow} 
			dialogHeader={uFormdataObj.dlg_header || ""}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}/>

		<MyDialogview
			options={{size:"lg",centered:true}}
			show={uISDlgviewshow} 
			dialogHeader={uDlgviewdataObj.dlg_header||""}
			toggle={()=>setDlgviewshow(false)}
			renderContent={contentDlgview()}/>

		<iframe id="ifmcontentstoprint" className="d-none"/>
		<div className="d-none">
			{(uSystemsettingObj.url_image_nota||"")!=="" && (
				<img src={uSystemsettingObj.url_image_nota} height="100"/>
			)}
		</div>
		</>
	)//>
}	

export default Lapkunjungan